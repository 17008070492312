import sdata from "./shaders/sdata.json";

class Shader {
  constructor(vert, frag, uniforms, attribs) {
    this.vertSource = vert;
    this.fragSource = frag;
    this.uniforms = uniforms;
    this.attribs = attribs;
    this.program = null;
  }

  static loadShader(gl, type, source) {
    const shader = gl.createShader(type);

    // Send the source to the shader object
    gl.shaderSource(shader, source);

    // Compile the shader program
    gl.compileShader(shader);

    // See if it compiled successfully
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      // Fail with an error message
      var typeStr = "";
      if (type === gl.VERTEX_SHADER) {
        typeStr = "VERTEX";
      } else if (type === gl.FRAGMENT_SHADER) {
        typeStr = "FRAGMENT";
      }
      const errMessage =
        "An error occurred compiling the shader: " +
        typeStr +
        " -> " +
        gl.getShaderInfoLog(shader);
      gl.deleteShader(shader);
      throw new Error(errMessage);
    }

    return shader;
  }

  compileProgram(gl, name) {
    const vertexShader = Shader.loadShader(
      gl,
      gl.VERTEX_SHADER,
      this.vertSource,
    );
    const fragmentShader = Shader.loadShader(
      gl,
      gl.FRAGMENT_SHADER,
      this.fragSource,
    );

    // Create the shader program by attaching and linking the shader objects
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // If creating the shader program failed, throw an error
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
      throw new Error(
        `Unable to link the shader program ${name} error:${gl.getProgramInfoLog(shaderProgram)}`,
      );
    }
    this.program = shaderProgram;
  }

  getProgram() {
    return this.program;
  }
}

class ShaderManager {
  constructor(sdata) {
    if (!ShaderManager.instance) {
      this.shaders = {};
      this.program = null;

      // parse the json data and convert data from base64
      Object.keys(sdata).forEach((k) => {
        const s = sdata[k];
        // a bit ugly here to use `atob` but it works :shrug:
        const v = atob(s["v"]);
        const f = atob(s["f"]);
        this.shaders[k] = new Shader(v, f, s["uniforms"], s["attribs"]);
      });
      ShaderManager.instance = this;
    }
  }

  compileAll(gl) {
    Object.keys(this.shaders).forEach((s) => {
      this.shaders[s].compileProgram(gl, s);
    });
  }

  getShader(name) {
    if (this.shaders[name]) {
      return this.shaders[name];
    }

    throw new Error(`shader not found with name ${name}`);
  }
}

// we create a singleton of ShaderManager so we only load in shaders and compile them once
const shaderManagerInstance = new ShaderManager(sdata);
Object.freeze(shaderManagerInstance);
export default shaderManagerInstance;

export const SHADER_TYPES = {
  BASIC: 0,
  BLINN_NO_TEXTURE: 1,
  BASIC_DEPTH: 2,
  BLINN_TEXTURE: 3,
  BLINN_NORMAL_TEXTURE: 4,
  SKY_BOX_TEXTURE: 5,
  ENVIRONMENT_MAP: 6,
};
