import { vec3 } from "gl-matrix";

// base class light - will contain stuff for all other lights
class Light {
  constructor(glContext, object) {
    this.gl = glContext;
    this.name = object.name;
    this.parent = object.parent;
    this.type = "pointLight";
    this.loaded = false;
    this.position = object.position;
    this.colour = vec3.fromValues(
      object.colour[0],
      object.colour[1],
      object.colour[2],
    );
    this.strength = object.strength;
    this.quadratic = object.quadratic;
    this.linear = object.linear;
    this.constant = object.constant;
    this.nearPlane = object.nearPlane;
    this.farPlane = object.farPlane;
    this.shadow = object.shadow;
  }

  translate(translateVec) {
    vec3.add(
      this.position,
      this.position,
      vec3.fromValues(translateVec[0], translateVec[1], translateVec[2]),
    );
  }

  exportValues(additionalValues = {}) {
    return {
      name: this.name,
      colour: [this.colour[0], this.colour[1], this.colour[2]],
      position: this.position,
      strength: this.strength,
      quadratic: this.quadratic,
      linear: this.linear,
      parent: this.parent,
      constant: this.constant,
      nearPlane: this.nearPlane,
      farPlane: this.farPlane,
      shadow: this.shadow,
      ...additionalValues,
    };
  }
}

export default Light;
