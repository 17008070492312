import CustomObject from "./objects/CustomObject";
import PointLight from "./lighting/PointLight";
import Common from "./CommonRender";
import { vec4, mat4 } from "gl-matrix";
const _ = require("lodash");

export function addNewLight(state, lightObject) {
  let tempLight = new PointLight(state.gl, {
    name: lightObject.name,
    colour: lightObject.colour ? lightObject.colour : [1.0, 1.0, 1.0],
    position: lightObject.position ? lightObject.position : [0.0, 0.0, 0.0],
    strength: lightObject.strength ? lightObject.strength : 1,
    quadratic: lightObject.quadratic ? lightObject.quadratic : 0.035,
    linear: lightObject.linear ? lightObject.linear : 0.09,
    constant: lightObject.constant ? lightObject.constant : 1,
    nearPlane: lightObject.nearPlane ? lightObject.nearPlane : 0.5,
    farPlane: lightObject.farPlane ? lightObject.farPlane : 100,
    shadow: lightObject.shadow ? lightObject.shadow : 0,
  });
  state.addPointLight(tempLight);
}

export function deleteObject(state) {
  //check if any object is a child of this object
  let objects = Object.values(state.getObjects());
  let pointLights = Object.values(state.getPointLights());

  objects.forEach((object) => {
    if (object.parent === state.selectedObject.name) {
      object.parent = null;
    }
  });

  pointLights.forEach((light) => {
    if (light.parent === state.selectedObject.name) {
      light.parent = null;
    }
  });

  if (state.selectedObject.type === "pointLight") {
    //remove the light from the scene
    state.removePointLight(state.selectedObject);
    state.render = true;
    state.selectedObject = null;
  } else {
    state.removeObject(state.selectedObject);
    state.render = true;
    state.selectedObject = null;
  }
}

export async function saveNewCustomObject(state, object, objectSelectCB) {
  let newVerts = [];

  for (let i = 0; i < object.model.vertices.length; i += 3) {
    let tempVertex = vec4.fromValues(
      object.model.vertices[i],
      object.model.vertices[i + 1],
      object.model.vertices[i + 2],
      1.0,
    );
    vec4.transformMat4(tempVertex, tempVertex, object.model.modelMatrix);
    newVerts.push(tempVertex[0], tempVertex[1], tempVertex[2]);
  }

  let newObject = new CustomObject(this.state.gl, {
    name: object.name,
    type: object.type.includes("Custom") ? object.type : object.type + "Custom",
    material: object.material,
    scale: [1.0, 1.0, 1.0],
    rotation: mat4.create(),
    position: object.model.position,
    model: {
      vertices: newVerts.flat(),
      triangles: object.model.triangles,
      normals: object.model.normals,
      uvs: object.model.uvs,
    },
  });
  await newObject.setup();
  state.objects.push(newObject);
  object.name += "old";

  let oldObjectIndex = _.findIndex(this.state.objects, {
    name: object.name,
  });

  // this.handleObjectSelect(newObject);
  objectSelectCB(newObject);

  state.objects.splice(oldObjectIndex, 1);
}

export function handleObjectColourChange(state, event, object) {
  object.material.diffuse = Common.hexToRGB(event.target.value);
  state.render = true;
}

export function handleObjectAlphaChange(state, event, object) {
  object.material.alpha = event.target.value;
  state.render = true;
}

export function handleObjectNValueChange(state, event, object) {
  object.material.n = event.target.value;
  state.render = true;
}

export function handleLightColorChange(state, event, light) {
  light.colour = Common.hexToRGB(event.target.value);
  state.render = true;
}

export function handleObjectNameChange(state, event, object) {
  //lookup if any other objects have this object as its parent
  let newName = event.target.value;

  if (newName === object.name) {
    return true;
  } else if (newName === "") {
    return false;
  }
  let valid = true;

  Object.entries(state.getObjects()).forEach((obj) => {
    if (obj[0] === newName && obj[1] !== object) {
      valid = false;
    }

    if (obj[1].parent === object.name) {
      obj[1].parent = newName;
    }
  });

  Object.entries(state.getPointLights()).forEach((light) => {
    if (light[0] === newName && light[1] !== object) {
      valid = false;
    }
  });

  if (valid) {
    // check if light or object here
    if (object.type === "pointLight") {
      state.removePointLight(object);
      object.name = newName;
      state.addPointLight(object);
    } else {
      state.removeObject(object);
      object.name = newName;
      state.addObject(object);
    }
  }
  return valid;
}

export function getObjectDiffuseColor(object) {
  return Common.rgbToHex(object.material.diffuse);
}

export function getObjectAlpha(object) {
  return object.material.alpha;
}

export function getLightColor(light) {
  return Common.rgbToHex(light.colour);
}
