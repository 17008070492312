import { vec2, vec3 } from "gl-matrix";
import parseMTL from "./MaterialParser";
const OBJLoader = require("./Three-OBJ-Loader");

function createSegmented(size, segments) {
  let vertices = [];
  let triangles = [];
  let normals = [];
  let uvs = [];
  let currentIndex = 0;

  let step = size / segments;

  for (let i = 0; i < size; i += step) {
    for (let j = 0; j < size; j += step) {
      //0
      vertices = vertices.concat([j, 0, i]);
      //1
      vertices = vertices.concat([j + step, 0, i]);
      //2
      vertices = vertices.concat([j, 0, i + step]);
      //3
      vertices = vertices.concat([j, 0, i + step]);
      //4
      vertices = vertices.concat([j + step, 0, i]);
      //5
      vertices = vertices.concat([j + step, 0, i + step]);
      triangles = triangles.concat([
        0 + currentIndex,
        2 + currentIndex,
        1 + currentIndex,
        3 + currentIndex,
        5 + currentIndex,
        4 + currentIndex,
      ]);
      currentIndex += 6;
      normals = normals.concat([
        0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0,
        0.0, 0.0, 1.0, 0.0,
      ]);
      uvs = uvs.concat([
        j,
        i,
        j + step,
        i,
        j,
        i + step,
        j,
        i + step,
        j + step,
        i,
        j + step,
        i + step,
      ]);
    }
  }

  return {
    vertices,
    triangles,
    normals,
    uvs,
  };
}

function loadJSONFile(cb, filePath) {
  fetch(filePath)
    .then((data) => {
      return data.json();
    })
    .then((jData) => {
      cb(jData);
    })
    .catch((err) => {
      console.error(err);
    });
}

function parseOBJFileToJSON(objFileURL, object, meshDetails) {
  return new Promise((resolve, reject) => {
    if (objFileURL && !meshDetails) {
      fetch(objFileURL)
        .then((data) => {
          return data.text();
        })
        .then(async (text) => {
          let objects = [];
          let mesh = await OBJLoader.default.parse(text);
          let parentObj;
          //iterate through objects
          for (let j = 0; j < mesh.length; j++) {
            //iterate through the materials of the mesh
            for (let i = 0; i < mesh[j].materials.length; i++) {
              let vertices, uvs, normals;
              if (
                mesh[j].materials[i].groupStart &&
                mesh[j].materials[i].groupEnd
              ) {
                vertices = mesh[j].geometry.vertices.slice(
                  mesh[j].materials[i].groupStart * 3,
                  mesh[j].materials[i].groupEnd * 3,
                );
                uvs = mesh[j].geometry.uvs.slice(
                  mesh[j].materials[i].groupStart * 2,
                  mesh[j].materials[i].groupEnd * 2,
                );
                normals = mesh[j].geometry.normals.slice(
                  mesh[j].materials[i].groupStart * 3,
                  mesh[j].materials[i].groupEnd * 3,
                );
              } else {
                vertices = mesh[j].geometry.vertices;
                uvs = mesh[j].geometry.uvs;
                normals = mesh[j].geometry.normals;
              }

              let newObject = { ...object };

              if (j > 0) {
                newObject.name = object.name + i;
                newObject.parent = object.name;
                newObject.parentTransform = object.position;
              }

              if (i > 0) {
                newObject.name = newObject.name + i;
                newObject.parent = object.name;
                newObject.position = [0, 0, 0];
                newObject.scale = [1, 1, 1];

                newObject.parentTransform = parentObj.position;

                newObject.type = "mesh";
                let geometry = {
                  vertices,
                  uvs,
                  normals,
                };

                let results = await parseMTL(
                  mesh[j].materials[i].mtllib,
                  mesh[j].materials[i].name,
                  newObject,
                  geometry,
                );
                objects.push(results);
              } else {
                parentObj = newObject;
                let geometry = {
                  vertices,
                  uvs,
                  normals,
                };
                let results = await parseMTL(
                  mesh[j].materials[i].mtllib,
                  mesh[j].materials[i].name,
                  newObject,
                  geometry,
                );
                objects.push(results);
              }
            }
          }
          resolve(objects);
        })
        .catch((err) => {
          reject(err);
        });
    } else if (!objFileURL && meshDetails) {
      let mesh = meshDetails;
      // let objects = [];
      let parentObj;
      //iterate through objects

      Promise.all(
        mesh.map((innerMesh, j) => {
          return new Promise((innerResolve, innerReject) => {
            //iterate through the materials of the mesh
            for (let i = 0; i < mesh[j].materials.length; i++) {
              let vertices, uvs, normals;
              if (
                mesh[j].materials[i].groupStart &&
                mesh[j].materials[i].groupEnd
              ) {
                vertices = mesh[j].geometry.vertices.slice(
                  mesh[j].materials[i].groupStart * 3,
                  mesh[j].materials[i].groupEnd * 3,
                );
                uvs = mesh[j].geometry.uvs.slice(
                  mesh[j].materials[i].groupStart * 2,
                  mesh[j].materials[i].groupEnd * 2,
                );
                normals = mesh[j].geometry.normals.slice(
                  mesh[j].materials[i].groupStart * 3,
                  mesh[j].materials[i].groupEnd * 3,
                );
              } else {
                vertices = mesh[j].geometry.vertices;
                uvs = mesh[j].geometry.uvs;
                normals = mesh[j].geometry.normals;
              }

              let newObject = { ...object };

              if (j > 0) {
                newObject.name = object.name + i;
                newObject.parent = object.name;
                newObject.parentTransform = object.position;
              }

              if (i > 0) {
                newObject.name = newObject.name + i;
                newObject.parent = object.name;
                newObject.position = [0, 0, 0];
                newObject.scale = [1, 1, 1];

                newObject.parentTransform = parentObj.position;

                newObject.type = "mesh";
                let geometry = {
                  vertices,
                  uvs,
                  normals,
                };

                parseMTL(
                  mesh[j].materials[i].mtllib,
                  mesh[j].materials[i].name,
                  newObject,
                  geometry,
                )
                  .then((results) => {
                    innerResolve(results);
                  })
                  .catch((err) => {
                    innerReject(err);
                  });
              } else {
                parentObj = newObject;
                let geometry = {
                  vertices,
                  uvs,
                  normals,
                };
                parseMTL(
                  mesh[j].materials[i].mtllib,
                  mesh[j].materials[i].name,
                  newObject,
                  geometry,
                )
                  .then((results) => {
                    innerResolve(results);
                  })
                  .catch((err) => {
                    innerReject(err);
                  });
              }
            }
          });
        }),
      ).then((values) => {
        resolve(values);
      });
    }
  });
}

/**
 *
 * @param {hex value of color} hex
 */
function hexToRGB(hex) {
  let r = hex.substring(1, 3);
  let g = hex.substring(3, 5);
  let b = hex.substring(5, 7);
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  return [r / 255, g / 255, b / 255];
}

function RGBToHex(RGB) {
  const [r, g, b] = RGB;

  const toHex = (component) => {
    const hex = Math.round(component * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function componentToHex(c) {
  let val = Math.floor(c * 255);
  let hex = val.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(rgb) {
  return (
    "#" +
    componentToHex(rgb[0]) +
    componentToHex(rgb[1]) +
    componentToHex(rgb[2])
  );
}

function parseSceneFile(file, state, cb) {
  state.pointLights = [];
  state.objects = [];
  state.directionalLights = [];

  fetch(file)
    .then((data) => {
      return data.json();
    })
    .then((jData) => {
      state.level = jData[0];
      state.numberOfObjectsToLoad = jData[0].objects.length;
      state.settings = jData[0].settings;
      //create camera if it exists, otherwise set to default
      if (jData[0].settings.camera) {
        state.camera = {
          name: jData[0].settings.camera.name,
          position: vec3.fromValues(
            jData[0].settings.camera.position[0],
            jData[0].settings.camera.position[1],
            jData[0].settings.camera.position[2],
          ),
          front: vec3.fromValues(
            jData[0].settings.camera.front[0],
            jData[0].settings.camera.front[1],
            jData[0].settings.camera.front[2],
          ),
          up: vec3.fromValues(
            jData[0].settings.camera.up[0],
            jData[0].settings.camera.up[1],
            jData[0].settings.camera.up[2],
          ),
          pitch: jData[0].settings.camera.pitch,
          yaw: jData[0].settings.camera.yaw,
          roll: jData[0].settings.camera.pitch.roll,
        };
      } else {
        state.camera = {
          name: "camera",
          position: vec3.fromValues(0, 0, 0),
          front: vec3.fromValues(0.0, 0.0, 1.0),
          up: vec3.fromValues(0.0, 1.0, 0.0),
          pitch: 0,
          yaw: 90, //works when the camera front is 0, 0, 1 to start
          roll: 0,
        };
      }

      cb();
    })
    .catch((err) => {
      console.error(err);
    });
}

function createSceneFile(state) {
  let totalState = [
    {
      objects: [],
      pointLights: [],
      skyBoxOn: state.skyBoxOn,
      skyBox: state.skyBoxPath,
      settings: state.sceneSettings,
    },
  ];

  //objects first
  Object.values(state.objects).forEach((object) => {
    totalState[0].objects.push(object.exportValues());
  });

  Object.values(state.pointLights).forEach((light) => {
    totalState[0].pointLights.push(light.exportValues());
  });

  return totalState;
}

function mat4ToArray(mat) {
  let array = [];

  for (let i = 0; i < mat.length; i++) {
    array.push(mat[i]);
  }

  return array;
}

function initShaderUniforms(gl, shaderProgram, uniforms, attribs) {
  let programInfo = {
    attribLocations: {},
    uniformLocations: {},
  };

  //map and check attribs
  attribs.map((attrib) => {
    programInfo.attribLocations[attrib] = gl.getAttribLocation(
      shaderProgram,
      attrib,
    );
  });

  //map and check uniforms
  uniforms.map((uniform) => {
    programInfo.uniformLocations[uniform] = gl.getUniformLocation(
      shaderProgram,
      uniform,
    );
  });

  programInfo.program = shaderProgram;

  return programInfo;
}

function getVertexRowN(vertices, n) {
  let vertex = vec3.fromValues(
    vertices[n * 3],
    vertices[n * 3 + 1],
    vertices[n * 3 + 2],
  );
  return vertex;
}

function getUVRowN(uvs, n) {
  let uv = vec2.fromValues(uvs[n * 2], uvs[n * 2 + 1]);
  return uv;
}

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

function initDepthMap(gl, width, height) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(
    gl.TEXTURE_2D, // target
    0, // mip level
    gl.DEPTH_COMPONENT, // internal format
    width, // width
    height, // height
    0, // border
    gl.DEPTH_COMPONENT, // format
    gl.UNSIGNED_INT, // type
    null,
  ); // data
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

  const depthMapFBO = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, depthMapFBO);
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER, // target
    gl.DEPTH_ATTACHMENT, // attachment point
    gl.TEXTURE_2D, // texture target
    texture, // texture
    0,
  ); // mip level

  return { depthMapFBO, texture };
}

function vectorDistance(vec1, vec2) {
  let xDiff = vec2[0] - vec1[0];
  let yDiff = vec2[1] - vec1[1];
  let zDiff = vec2[2] - vec1[2];

  return Math.sqrt(xDiff + yDiff + zDiff);
}

function handleUIPositionChange(state, position, value) {}

const Common = {
  componentToHex,
  createSceneFile,
  getUVRowN,
  getVertexRowN,
  handleUIPositionChange,
  hexToRGB,
  RGBToHex,
  initDepthMap,
  initShaderUniforms,
  loadJSONFile,
  mat4ToArray,
  parseOBJFileToJSON,
  parseSceneFile,
  rgbToHex,
  toRadians,
  vectorDistance,
  createSegmented,
};

export default Common;
