import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../constants";

const AssetsModal = ({
  isOpen,
  closeModal,
  getTextureOptions,
  textureOptions,
  textureIDs,
  getMeshOptions,
  meshOptions,
  meshIDs,
}) => {
  const [transitionState, setTransitionState] = useState("");
  const [textureFile, setTextureFile] = useState(null);
  // const [selectedTexture, setSelectedTexture] = useState("");
  const [meshFile, setMeshFile] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setTransitionState("animating fade in");
    } else {
      setTransitionState("animating fade out");
    }
  }, [isOpen]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTextureFile(file);
    }
  };

  const handleMeshFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMeshFile(file);
    }
  };

  const postTexture = async () => {
    try {
      const token = localStorage.getItem("jwt");

      const formData = new FormData();
      formData.append("file", textureFile, textureFile.filename);

      const response = await fetch(`${BACKEND_URL}/textures`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Create Texture Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const postMesh = async () => {
    try {
      const token = localStorage.getItem("jwt");

      const formData = new FormData();
      formData.append("file", meshFile, meshFile.filename);

      const response = await fetch(`${BACKEND_URL}/meshes`, {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Create Mesh Failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCreateTexture = async () => {
    if (!textureFile) {
      alert("Please select a texture file.");
      return;
    }

    if (textureOptions.includes(textureFile.name)) {
      alert("Filename already exists! Please try a different file.");
      return;
    }

    await postTexture();
    await getTextureOptions();

    setTextureFile(null);
    closeModal();
  };

  const handleCreateMesh = async () => {
    if (!meshFile) {
      alert("Please select a mesh file.");
      return;
    }

    if (meshOptions.includes(meshFile.name)) {
      alert("Filename already exists! Please try a different file.");
      return;
    }

    await postMesh();
    await getMeshOptions();

    setMeshFile(null);
    closeModal();
  };

  return (
    <div>
      {isOpen && (
        <div>
          <div
            className={`ui dimmer modals visible active ${transitionState}`}
            onClick={closeModal}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
            }}
          ></div>

          <div
            className={`ui modal active ${transitionState}`}
            style={{
              zIndex: 1050,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              margin: "0",
              width: "30vw",
            }}
          >
            <div className="header" style={{ backgroundColor: "#00b5ad" }}>
              <h2 className="ui header" style={{ color: "white", margin: 0 }}>
                Assets
              </h2>
            </div>
            <div className="content">
              <h3 className="ui teal header" style={{ fontWeight: "400" }}>
                <p>Select a mesh</p>
              </h3>
              <div className="ui teal input" style={{ width: "250px" }}>
                <input
                  type="file"
                  accept=".obj"
                  onChange={handleMeshFileChange}
                />
              </div>
              <h3 className="ui teal header" style={{ fontWeight: "400" }}>
                <p>Select a texture</p>
              </h3>
              <div className="ui teal input" style={{ width: "250px" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {/* CODE FOR DELETING TEXTURES BELOW */}
              {/* <h3 className="ui teal header" style={{ fontWeight: "400" }}>
                <p>Delete textures</p>
                <select
                  className="ui selection dropdown"
                  style={{ padding: "5px", color: "teal" }}
                  value={selectedTexture}
                  onChange={(e) => setSelectedTexture(e.target.value)} // Update the selected texture
                >
                  <option value="" disabled>
                    Select a texture
                  </option>
                  {textureOptions.map((option) => (
                    <option key={option} value={option} style={{ color: "teal" }}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  className="ui red button"
                  onClick={deleteTexture}
                  disabled={!selectedTexture} // Disable if no texture is selected
                >
                  Delete
                </button>
              </h3> */}
            </div>
            <div className="actions">
              <div className="ui green button" onClick={handleCreateMesh}>
                <i className="add icon"></i>
                Create Mesh
              </div>
              <div className="ui green button" onClick={handleCreateTexture}>
                <i className="add icon"></i>
                Create Texture
              </div>
              <button className="ui red button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetsModal;
