import { vec3, mat4 } from "gl-matrix";
import Common from "../CommonRender";
import RenderObject from "./RenderObject";
import shaderManagerInstance, { SHADER_TYPES } from "../ShaderManager";

class Mesh extends RenderObject {
  constructor(glContext, object, meshDetails) {
    super(object);
    this.state = {};
    this.gl = glContext;
    this.name = object.name;
    this.parent = object.parent;
    this.type = object.type;
    this.loaded = false;
    this.initialTransform = {
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
    };
    this.reflective = object.reflective;
    this.refractionIndex = object.refractionIndex;
    this.material = object.material;
    this.collide = object.collide;
    this.modelName = object.modelName;
    this.model = {
      vertices: [],
      uvs: [],
      normals: [],
      bitangents: [],
      diffuseTexture: object.diffuseTexture
        ? object.diffuseTexture
        : "default.jpg",
      normalTexture: object.normalTexture
        ? object.normalTexture
        : "defaultNorm.jpg",
      tid: object.tid ? object.tid : null,
      mid: object.mid ? object.mid : null,
      buffers: null,
      modelMatrix: mat4.create(),
      position: vec3.fromValues(0.0, 0.0, 0.0),
      rotation: mat4.create(),
      scale: vec3.fromValues(1.0, 1.0, 1.0),
    };
    this.parseGeometryArrays(meshDetails);
  }

  parseGeometryArrays(meshDetails) {
    meshDetails.forEach((geoData) => {
      this.model.vertices = [...this.model.vertices, ...geoData.vertices];
      this.model.normals = [...this.model.normals, ...geoData.normals];
      this.model.uvs = [...this.model.uvs, ...geoData.uvs];
    });
  }

  rotate(axis, angle) {
    if (axis === "x") {
      mat4.rotateX(this.model.rotation, this.model.rotation, angle);
    } else if (axis == "y") {
      mat4.rotateY(this.model.rotation, this.model.rotation, angle);
    } else if (axis == "z") {
      mat4.rotateZ(this.model.rotation, this.model.rotation, angle);
    }
  }

  scale(scaleVec) {
    //model scale
    let xVal = this.model.scale[0];
    let yVal = this.model.scale[1];
    let zVal = this.model.scale[2];

    xVal *= scaleVec[0];
    yVal *= scaleVec[1];
    zVal *= scaleVec[2];

    //need to scale bounding box
    this.model.scale = vec3.fromValues(xVal, yVal, zVal);
  }

  translate(translateVec) {
    vec3.add(this.model.position, this.model.position, translateVec);
  }

  lightingShader() {
    return new Promise((resolve, reject) => {
      var shaderProgram;
      var programInfo;

      if (this.material.shaderType === SHADER_TYPES.BASIC) {
        const shader = shaderManagerInstance.getShader("basicShader");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_NO_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnNoTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BASIC_DEPTH) {
        const shader = shaderManagerInstance.getShader("basicDepth");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (
        this.material.shaderType === SHADER_TYPES.BLINN_NORMAL_TEXTURE
      ) {
        const shader = shaderManagerInstance.getShader("blinnDiffuseAndNormal");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.ENVIRONMENT_MAP) {
        const shader = shaderManagerInstance.getShader("environmentMap");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else {
        reject("No shader found for this material type");
      }
    });
  }

  initBuffers() {
    return new Promise((resolve) => {
      //create vertices, normal and indicies arrays
      const positions = new Float32Array(this.model.vertices);
      const normals = new Float32Array(this.model.normals);
      const textureCoords = new Float32Array(this.model.uvs);
      const bitangents = new Float32Array(this.model.bitangents);
      const tangents = new Float32Array(this.model.tangents);

      var vertexArrayObject = this.gl.createVertexArray();
      this.gl.bindVertexArray(vertexArrayObject);

      this.buffers = {
        vao: vertexArrayObject,
        attributes: {
          position:
            this.programInfo.attribLocations.vertexPosition != null
              ? this.initPositionAttribute(this.gl, this.programInfo, positions)
              : null,
          normal:
            this.programInfo.attribLocations.vertexNormal != null
              ? this.initNormalAttribute(this.gl, this.programInfo, normals)
              : null,
          uv:
            this.programInfo.attribLocations.vertexUV != null
              ? this.initTextureCoords(this.gl, this.programInfo, textureCoords)
              : null,
          bitangents:
            this.programInfo.attribLocations.vertexBitangent != null
              ? this.initBitangentBuffer(this.gl, this.programInfo, bitangents)
              : null,
          tangents:
            this.programInfo.attribLocations.vertexTangent != null
              ? this.initBitangentBuffer(this.gl, this.programInfo, tangents)
              : null,
        },
        numVertices: this.model.vertices.length,
      };
      resolve();
    });
  }

  reset() {
    return new Promise((resolve, reject) => {
      this.setup(true)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setup(reset) {
    return new Promise((resolve, reject) => {
      const tangentData = this.calculateBitangents(false);
      this.model.bitangents = tangentData.bitangents;
      this.model.tangents = tangentData.tangents;
      this.getTextures(this.gl, this.model.diffuseTexture, this.model.tid)
        .then((data) => {
          this.model.texture = data;
          this.getTextures(this.gl, this.model.normalTexture, this.model.tid)
            .then((data) => {
              this.model.textureNorm = data;
              this.calculateCentroid(this.model.vertices);
              this.lightingShader().then(() => {
                this.initBuffers().then(() => {
                  if (!reset) {
                    this.scale(this.initialTransform.scale);
                    this.translate(this.initialTransform.position);

                    if (this.initialTransform.rotation) {
                      this.model.rotation = this.initialTransform.rotation;
                    }
                  }
                  this.loaded = true;
                  resolve();
                });
              });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete() {
    Object.keys(this.buffers.attributes).forEach((key) => {
      this.gl.deleteBuffer(this.buffers.attributes[key]);
    });
    this.gl.deleteBuffer(this.buffers.indicies);
  }
}

export default Mesh;
