import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SettingsModal from "./SettingsModal";
import AssetsModal from "./AssetsModal";
import { BACKEND_URL } from "../constants";

const saveScene = async () => {
  try {
    const token = localStorage.getItem("jwt");
    const sid = localStorage.getItem("currSID");
    const sceneData = JSON.parse(localStorage.getItem("currSceneData"));

    const jsonBlob = new Blob([JSON.stringify(sceneData)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("file", jsonBlob, "TestSceneFrontEnd");
    formData.append("sid", sid);

    const response = await fetch(`${BACKEND_URL}/scenes`, {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Save Scene Failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

function EditSceneSubHeader({
  saveFunction,
  skyBoxOn,
  handleSkyBoxToggle,
  handleTemplateDownload,
  handleSceneDownload,
  getBackgroundColour,
  handleBackgroundColourChange,
  getSkybox,
  handleSkyBoxChange,
  getTextureOptions,
  textureOptions,
  textureIDs,
  getMeshOptions,
  meshOptions,
  meshIDs,
}) {
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenAssets, setIsOpenAssets] = useState(false);
  const navigate = useNavigate();

  const openSettingsModal = () => {
    setIsOpenSettings(true);
  };

  const closeSettingsModal = () => {
    setIsOpenSettings(false);
  };

  const openAssetsModal = () => {
    setIsOpenAssets(true);
  };

  const closeAssetsModal = () => {
    setIsOpenAssets(false);
  };

  const handleSaveClick = async () => {
    await saveFunction();
    await saveScene();
    window.location.reload();
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <div
        className="ui grid"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "5px",
          justifyContent: "space-between",
        }}
      >
        {localStorage.getItem("jwt") ? (
          <div style={{ flex: "0 0 auto" }}>
            <div
              className="ui raised segment"
              style={{
                backgroundColor: "white",
                width: "230px",
                marginLeft: "15px",
              }}
            >
              <div className="ui center aligned container">
                <h2
                  className="ui header"
                  style={{ color: "#00b5ad", margin: 0 }}
                >
                  <div className="column">
                    <div
                      className="ui center floated teal button"
                      onClick={() => handleBackClick()}
                      style={{ marginRight: "10px" }}
                    >
                      <i className="arrow left icon"></i>
                      Back
                    </div>
                    <div
                      className="ui teal button"
                      onClick={() => handleSaveClick()}
                    >
                      <i className="check icon"></i>
                      Save
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ flex: "0 0 auto", marginRight: "15px" }} />
        )}
        {localStorage.getItem("jwt") ? (
          <div style={{ flex: "0 0 auto" }}>
            <div
              className="ui raised segment"
              style={{
                backgroundColor: "white",
                width: "auto",
              }}
            >
              <h2 className="ui header" style={{ color: "#00b5ad", margin: 0 }}>
                {localStorage.getItem("currSceneName")}
              </h2>
            </div>
          </div>
        ) : (
          <div style={{ flex: "0 0 auto", marginRight: "15px" }} />
        )}
        <div style={{ flex: "0 0 auto", marginRight: "15px" }}>
          <div
            className="ui raised segment"
            style={{
              backgroundColor: "white",
              width: "auto",
            }}
          >
            {localStorage.getItem("jwt") && (
              <div
                className="ui teal button"
                onClick={openAssetsModal}
                style={{ marginRight: "10px" }}
              >
                <i className="image icon"></i>
                Assets
              </div>
            )}
            <div className="ui teal button" onClick={openSettingsModal}>
              <i className="setting icon"></i>
              Scene Settings
            </div>
          </div>
        </div>
      </div>
      <SettingsModal
        isOpen={isOpenSettings}
        closeModal={closeSettingsModal}
        handleSkyBoxToggle={handleSkyBoxToggle}
        skyBoxOn={skyBoxOn}
        handleTemplateDownload={handleTemplateDownload}
        handleSceneDownload={handleSceneDownload}
        getBackgroundColour={getBackgroundColour}
        handleBackgroundColourChange={handleBackgroundColourChange}
        getSkybox={getSkybox}
        handleSkyBoxChange={handleSkyBoxChange}
      />
      <AssetsModal
        isOpen={isOpenAssets}
        closeModal={closeAssetsModal}
        getTextureOptions={getTextureOptions}
        textureOptions={textureOptions}
        textureIDs={textureIDs}
        getMeshOptions={getMeshOptions}
        meshOptions={meshOptions}
        meshIDs={meshIDs}
      />
    </div>
  );
}

export default EditSceneSubHeader;
