import { vec2, vec3 } from "gl-matrix";
import {
  BACKEND_URL,
  DIFFUSE_TEXTURES,
  NORMAL_TEXTURES,
} from "../../constants";
import Common from "../CommonRender";

class RenderObject {
  constructor(object) {
    this.name = object.name;
    this.rotationValues = [0.0, 0.0, 0.0];
    this.scaleValues = [0.0, 0.0, 0.0];
    this.model = {
      uvs: [],
      vertices: [],
      triangles: [],
    };
  }

  getRotationValues() {
    return this.rotationValues;
  }

  getScaleValues() {
    return this.scaleValues;
  }

  setRotationValues(values) {
    this.rotationValues = values;
  }

  setScaleValues(values) {
    this.scaleValues = values;
  }

  initIndexBuffer(gl, elementArray) {
    // Create a buffer for the positions.
    const indexBuffer = gl.createBuffer();

    // Select the buffer as the one to apply buffer
    // operations to from here out.
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.
    gl.bufferData(
      gl.ELEMENT_ARRAY_BUFFER, // The kind of buffer this is
      elementArray, // The data in an Array object
      gl.STATIC_DRAW, // We are not going to change this data, so it is static
    );

    return indexBuffer;
  }

  // used to get all values necessary to export a render object
  // can be used on children objects to include values that are specific to it
  exportValues(additionalValues = {}) {
    return {
      name: this.name ? this.name : null,
      material: this.material ? this.material : null,
      type: this.type ? this.type : null,
      position: this.model.position
        ? [
            this.model.position[0],
            this.model.position[1],
            this.model.position[2],
          ]
        : null,
      scale: this.model.scale
        ? [this.model.scale[0], this.model.scale[1], this.model.scale[2]]
        : null,
      diffuseTexture: this.model.diffuseTexture
        ? this.model.diffuseTexture
        : null,
      tid: this.model.tid ? this.model.tid : null,
      mid: this.model.mid ? this.model.mid : null,
      normalTexture: this.model.normalTexture ? this.model.normalTexture : null,
      rotation: this.model.rotation
        ? Common.mat4ToArray(this.model.rotation)
        : null,
      collide: this.collide,
      reflective: this.reflective,
      refractionIndex: this.refractionIndex,
      parent: this.parent ? this.parent : null,
      model: this.modelName ? this.modelName : null,
      ...additionalValues,
    };
  }

  loadTexture = async (tid) => {
    try {
      const token = localStorage.getItem("jwt");

      const response = await fetch(`${BACKEND_URL}/textures/${tid}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Load Texture Failed");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    } catch (error) {
      console.error("Error loading texture:", error);
    }
  };

  getTextures = async (gl, imgPath, tid, mesh = false) => {
    let path = "";
    if (
      DIFFUSE_TEXTURES.includes(imgPath) ||
      NORMAL_TEXTURES.includes(imgPath)
    ) {
      path = !mesh ? "./materials/" + imgPath : "./models/" + imgPath;
    } else {
      if (tid) {
        path = await this.loadTexture(tid);
      } else {
        path = "./materials/default.jpg";
      }
    }
    return new Promise((resolve, reject) => {
      if (path) {
        var texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          1,
          1,
          0,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          new Uint8Array([255, 0, 0, 255]),
        ); // red

        const image = new Image();
        image.src = path;

        image.onload = () => {
          gl.bindTexture(gl.TEXTURE_2D, texture);
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
          gl.texImage2D(
            gl.TEXTURE_2D,
            0,
            gl.RGBA,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            image,
          );
          resolve(texture);
        };
        image.onerror = (err) => {
          reject(err);
        };
      } else {
        reject("Cannot find texture " + path);
      }
    });
  };

  /**
   *
   * @param {array of x,y,z vertices} vertices
   */
  calculateCentroid(vertices) {
    var center = vec3.fromValues(0.0, 0.0, 0.0);
    for (let t = 0; t < vertices.length; t += 3) {
      vec3.add(
        center,
        center,
        vec3.fromValues(vertices[t], vertices[t + 1], vertices[t + 2]),
      );
    }
    vec3.scale(center, center, 1 / (vertices.length / 3));

    this.centroid = center;
  }
  initPositionAttribute(gl, programInfo, positionArray) {
    // Create a buffer for the positions.
    const positionBuffer = gl.createBuffer();

    // Select the buffer as the one to apply buffer
    // operations to from here out.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.
    gl.bufferData(
      gl.ARRAY_BUFFER, // The kind of buffer this is
      positionArray, // The data in an Array object
      gl.STATIC_DRAW, // We are not going to change this data, so it is static
    );

    // Tell WebGL how to pull out the positions from the position
    // buffer into the vertexPosition attribute.
    {
      const numComponents = 3; // pull out 3 values per iteration, ie vec3
      const type = gl.FLOAT; // the data in the buffer is 32bit floats
      const normalize = false; // don't normalize between 0 and 1
      const stride = 0; // how many bytes to get from one set of values to the next
      // Set stride to 0 to use type and numComponents above
      const offset = 0; // how many bytes inside the buffer to start from

      // Set the information WebGL needs to read the buffer properly
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexPosition,
        numComponents,
        type,
        normalize,
        stride,
        offset,
      );
      // Tell WebGL to use this attribute
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }

    return positionBuffer;
  }
  initNormalAttribute(gl, programInfo, normalArray) {
    // Create a buffer for the positions.
    const normalBuffer = gl.createBuffer();

    // Select the buffer as the one to apply buffer
    // operations to from here out.
    gl.bindBuffer(gl.ARRAY_BUFFER, normalBuffer);

    // Now pass the list of positions into WebGL to build the
    // shape. We do this by creating a Float32Array from the
    // JavaScript array, then use it to fill the current buffer.
    gl.bufferData(
      gl.ARRAY_BUFFER, // The kind of buffer this is
      normalArray, // The data in an Array object
      gl.STATIC_DRAW, // We are not going to change this data, so it is static
    );

    // Tell WebGL how to pull out the positions from the position
    // buffer into the vertexPosition attribute.
    {
      const numComponents = 3; // pull out 4 values per iteration, ie vec3
      const type = gl.FLOAT; // the data in the buffer is 32bit floats
      const normalize = false; // don't normalize between 0 and 1
      const stride = 0; // how many bytes to get from one set of values to the next
      // Set stride to 0 to use type and numComponents above
      const offset = 0; // how many bytes inside the buffer to start from

      // Set the information WebGL needs to read the buffer properly
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexNormal,
        numComponents,
        type,
        normalize,
        stride,
        offset,
      );
      // Tell WebGL to use this attribute
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexNormal);
    }

    return normalBuffer;
  }

  initTextureCoords(gl, programInfo, textureCoords) {
    if (textureCoords != null && textureCoords.length > 0) {
      // Create a buffer for the positions.
      const textureCoordBuffer = gl.createBuffer();

      // Select the buffer as the one to apply buffer
      // operations to from here out.
      gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

      // Now pass the list of positions into WebGL to build the
      // shape. We do this by creating a Float32Array from the
      // JavaScript array, then use it to fill the current buffer.
      gl.bufferData(
        gl.ARRAY_BUFFER, // The kind of buffer this is
        textureCoords, // The data in an Array object
        gl.STATIC_DRAW, // We are not going to change this data, so it is static
      );

      // Tell WebGL how to pull out the positions from the position
      // buffer into the vertexPosition attribute.
      {
        const numComponents = 2;
        const type = gl.FLOAT; // the data in the buffer is 32bit floats
        const normalize = false; // don't normalize between 0 and 1
        const stride = 0; // how many bytes to get from one set of values to the next
        // Set stride to 0 to use type and numComponents above
        const offset = 0; // how many bytes inside the buffer to start from

        // Set the information WebGL needs to read the buffer properly
        gl.vertexAttribPointer(
          programInfo.attribLocations.vertexUV,
          numComponents,
          type,
          normalize,
          stride,
          offset,
        );
        // Tell WebGL to use this attribute
        gl.enableVertexAttribArray(programInfo.attribLocations.vertexUV);
      }

      return textureCoordBuffer;
    }
  }

  initTangentBuffer(gl, programInfo, tangents) {
    if (tangents != null && tangents.length > 0) {
      // Create a buffer for the positions.
      const tangentBuffer = gl.createBuffer();

      // Select the buffer as the one to apply buffer
      // operations to from here out.
      gl.bindBuffer(gl.ARRAY_BUFFER, tangentBuffer);

      // Now pass the list of positions into WebGL to build the
      // shape. We do this by creating a Float32Array from the
      // JavaScript array, then use it to fill the current buffer.
      gl.bufferData(
        gl.ARRAY_BUFFER, // The kind of buffer this is
        tangents, // The data in an Array object
        gl.STATIC_DRAW, // We are not going to change this data, so it is static
      );

      // Tell WebGL how to pull out the positions from the position
      // buffer into the vertexPosition attribute.
      {
        const numComponents = 3;
        const type = gl.FLOAT; // the data in the buffer is 32bit floats
        const normalize = false; // don't normalize between 0 and 1
        const stride = 0; // how many bytes to get from one set of values to the next
        // Set stride to 0 to use type and numComponents above
        const offset = 0; // how many bytes inside the buffer to start from

        // Set the information WebGL needs to read the buffer properly
        gl.vertexAttribPointer(
          programInfo.attribLocations.vertexTangent,
          numComponents,
          type,
          normalize,
          stride,
          offset,
        );
        // Tell WebGL to use this attribute
        gl.enableVertexAttribArray(programInfo.attribLocations.vertexTangent);
      }
      return tangentBuffer;
    }
  }

  initBitangentBuffer(gl, programInfo, bitangents) {
    if (bitangents != null && bitangents.length > 0) {
      // Create a buffer for the positions.
      const bitangentBuffer = gl.createBuffer();

      // Select the buffer as the one to apply buffer
      // operations to from here out.
      gl.bindBuffer(gl.ARRAY_BUFFER, bitangentBuffer);

      // Now pass the list of positions into WebGL to build the
      // shape. We do this by creating a Float32Array from the
      // JavaScript array, then use it to fill the current buffer.
      gl.bufferData(
        gl.ARRAY_BUFFER, // The kind of buffer this is
        bitangents, // The data in an Array object
        gl.STATIC_DRAW, // We are not going to change this data, so it is static
      );

      // Tell WebGL how to pull out the positions from the position
      // buffer into the vertexPosition attribute.
      {
        const numComponents = 3;
        const type = gl.FLOAT; // the data in the buffer is 32bit floats
        const normalize = false; // don't normalize between 0 and 1
        const stride = 0; // how many bytes to get from one set of values to the next
        // Set stride to 0 to use type and numComponents above
        const offset = 0; // how many bytes inside the buffer to start from

        // Set the information WebGL needs to read the buffer properly
        gl.vertexAttribPointer(
          programInfo.attribLocations.vertexBitangent,
          numComponents,
          type,
          normalize,
          stride,
          offset,
        );
        // Tell WebGL to use this attribute
        gl.enableVertexAttribArray(programInfo.attribLocations.vertexBitangent);
      }
      return bitangentBuffer;
    }
  }

  getVertexRowN(vertices, n) {
    let vertex = vec3.fromValues(
      vertices[n * 3],
      vertices[n * 3 + 1],
      vertices[n * 3 + 2],
    );
    return vertex;
  }

  getUVRowN(uvs, n) {
    let uv = vec2.fromValues(uvs[n * 2], uvs[n * 2 + 1]);
    return uv;
  }

  calculateBitangents(hasIndices = true) {
    let vertices = this.model.vertices;
    let normals = this.model.normals;
    let uvs = this.model.uvs;
    const bitangents = [];
    const tangents = [];

    if (hasIndices) {
      let indices = this.model.triangles;

      for (let i = 0; i < indices.length; i += 3) {
        // Get the indices of the current triangle's vertices
        const index1 = indices[i];
        const index2 = indices[i + 1];
        const index3 = indices[i + 2];

        // Get the positions, normals, and UVs of the triangle's vertices
        const pos1 = [
          vertices[index1 * 3],
          vertices[index1 * 3 + 1],
          vertices[index1 * 3 + 2],
        ];
        const pos2 = [
          vertices[index2 * 3],
          vertices[index2 * 3 + 1],
          vertices[index2 * 3 + 2],
        ];
        const pos3 = [
          vertices[index3 * 3],
          vertices[index3 * 3 + 1],
          vertices[index3 * 3 + 2],
        ];

        const normal1 = [
          normals[index1 * 3],
          normals[index1 * 3 + 1],
          normals[index1 * 3 + 2],
        ];
        // const normal2 = [normals[index2 * 3], normals[index2 * 3 + 1], normals[index2 * 3 + 2]];
        // const normal3 = [normals[index3 * 3], normals[index3 * 3 + 1], normals[index3 * 3 + 2]];

        const uv1 = [uvs[index1 * 2], uvs[index1 * 2 + 1]];
        const uv2 = [uvs[index2 * 2], uvs[index2 * 2 + 1]];
        const uv3 = [uvs[index3 * 2], uvs[index3 * 2 + 1]];

        // Calculate the edge vectors and UV differentials
        const edge1 = vec3.subtract([], pos2, pos1);
        const edge2 = vec3.subtract([], pos3, pos1);
        const deltaUV1 = vec2.subtract([], uv2, uv1);
        const deltaUV2 = vec2.subtract([], uv3, uv1);

        // Calculate the tangent and bitangent (bitangent = cross(tangent, normal))
        const tangent = vec3.normalize(
          [],
          vec3.subtract(
            [],
            vec3.scale([], edge1, deltaUV2[1]),
            vec3.scale([], edge2, deltaUV1[1]),
          ),
        );
        const bitangent = vec3.cross([], tangent, normal1);

        // Assign the bitangent to each vertex of the triangle
        bitangents.push(bitangent, bitangent, bitangent);
        tangents.push(tangent, tangent, tangent);
      }
      return { bitangents, tangents };
    }

    for (let i = 0; i < vertices.length; i += 9) {
      // Get the positions, normals, and UVs of the triangle's vertices
      const pos1 = [vertices[i], vertices[i + 1], vertices[i + 2]];
      const pos2 = [vertices[i + 3], vertices[i + 4], vertices[i + 5]];
      const pos3 = [vertices[i + 6], vertices[i + 7], vertices[i + 8]];

      const normal1 = [normals[i], normals[i + 1], normals[i + 2]];

      const uv1 = [uvs[(i / 3) * 2], uvs[(i / 3) * 2 + 1]];
      const uv2 = [uvs[(i / 3) * 2 + 2], uvs[(i / 3) * 2 + 3]];
      const uv3 = [uvs[(i / 3) * 2 + 4], uvs[(i / 3) * 2 + 5]];

      // Calculate the edge vectors and UV differentials
      const edge1 = vec3.subtract([], pos2, pos1);
      const edge2 = vec3.subtract([], pos3, pos1);
      const deltaUV1 = vec2.subtract([], uv2, uv1);
      const deltaUV2 = vec2.subtract([], uv3, uv1);

      // Calculate the tangent and bitangent (bitangent = cross(tangent, normal))
      const tangent = vec3.normalize(
        [],
        vec3.subtract(
          [],
          vec3.scale([], edge1, deltaUV2[1]),
          vec3.scale([], edge2, deltaUV1[1]),
        ),
      );
      const bitangent = vec3.cross([], tangent, normal1);

      tangents.push(
        tangent[0],
        tangent[1],
        tangent[2],
        tangent[0],
        tangent[1],
        tangent[2],
        tangent[0],
        tangent[1],
        tangent[2],
      );
      // Assign the bitangent to each vertex of the triangle
      bitangents.push(
        bitangent[0],
        bitangent[1],
        bitangent[2],
        bitangent[0],
        bitangent[1],
        bitangent[2],
        bitangent[0],
        bitangent[1],
        bitangent[2],
      );
    }
    return { bitangents, tangents };
  }
}

export default RenderObject;
