import React, { createRef } from "react";
import AddObjectModal from "../components/AddObjectModal";
import EditSceneSubHeader from "../components/EditSceneSubHeader";
import sampleScene from "../components/sampleScene.json";

import { vec3 } from "gl-matrix";
import {
  Common,
  deleteObject,
  getLightColor,
  getObjectAlpha,
  getObjectDiffuseColor,
  handleLightColorChange,
  handleObjectAlphaChange,
  handleObjectColourChange,
  handleObjectNValueChange,
  SceneManager,
  shaderManagerInstance,
} from "../Refinery";
import PointLight from "../Refinery/lighting/PointLight";
import { BACKEND_URL, DIFFUSE_TEXTURES, NORMAL_TEXTURES } from "../constants";

class Workspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sceneManager: null,
    };
    this.canvasRef = createRef();
    this.skyBoxOn = false;
    this.addModalIsOpen = false;
    this.textureOptions = [];
    this.textureIDs = [];
    this.meshOptions = [];
    this.meshIDs = [];
  }

  loadScene = async () => {
    try {
      const token = localStorage.getItem("jwt");
      const sid = localStorage.getItem("currSID");

      const response = await fetch(`${BACKEND_URL}/scenes/${sid}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Load Scene Failed");
      }

      const sceneData = await response.json();
      return sceneData;
    } catch (error) {
      console.error("Error loading scene:", error);
    }
  };

  componentDidMount() {
    this.gigaLoad();
    window.addEventListener("resize", this.resizePage);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePage);
  }

  handleLightSelect = (light) => {
    const { sceneManager } = this.state;

    if (sceneManager) {
      sceneManager.selectedObject = light;
      sceneManager.refresh();
      this.setState({ sceneManager });
    }
  };

  handleObjectSelect = (object) => {
    const { sceneManager } = this.state;
    if (!object) {
      sceneManager.setSelectedObject(undefined);
    } else {
      if (sceneManager) {
        const objectData = sceneManager.getObject(object.name);
        sceneManager.setSelectedObject(objectData);

        if (objectData) {
          sceneManager.diffuseCheck = objectData.material.shaderType === 3;
          sceneManager.normalCheck = objectData.material.shaderType === 4;
        }
      }
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  resizePage = () => {
    const { sceneManager } = this.state;

    if (sceneManager) {
      const dimensions = {
        width: window.innerWidth - 10,
        height: window.innerHeight,
      };
      sceneManager.canvas.height = dimensions.height * 0.8;
      sceneManager.canvas.width = dimensions.width * 0.6;
      sceneManager.dimensions = {
        width: sceneManager.canvas.width,
        height: sceneManager.canvas.height,
      };

      sceneManager.initProjection({
        near: sceneManager.near,
        far: sceneManager.far,
        fov: sceneManager.fov,
      });
      sceneManager.render = true;
    }
  };

  handleSceneLoad = async (sm, data) => {
    if (
      data &&
      data.length > 0 &&
      data[0].objects &&
      data[0].pointLights &&
      data[0].settings
    ) {
      // reset our data
      sm.reset();
      for (const o of data[0].objects) {
        await sm.loadObject(o);
      }

      for (const l of data[0].pointLights) {
        await sm.addPointLight(l);
      }

      sm.setSettings(data[0].settings);
      sm.addCamera(data[0].settings.camera);
      sm.setSkyBoxOn(data[0].skyBoxOn);
      this.skyBoxOn = data[0].skyBoxOn;
      sm.refresh();
    } else {
      console.error("Scenefile has incorrect structure");
    }
  };

  gigaLoad = async () => {
    try {
      const canvas = this.canvasRef.current;
      let sceneData = null;

      if (localStorage.getItem("jwt")) {
        sceneData = await this.loadScene();
      } else {
        sceneData = sampleScene;
      }

      canvas.height = window.innerHeight * 0.65;
      canvas.width = window.innerWidth * 0.5;
      const gl = canvas.getContext("webgl2", { stencil: true });

      if (!gl) {
        console.error("WebGL2 not enabled properly for this machine!");
        return;
      }

      const ext = gl.getExtension("EXT_color_buffer_float");
      if (!ext) {
        alert("need EXT_color_buffer_float");
        return;
      }

      const sm = new SceneManager();
      sm.dimensions = { width: canvas.width, height: canvas.height };

      sm.handleSelect = (object) => this.handleObjectSelect(object);
      sm.setCanvas(canvas);
      sm.setGLContext(gl);
      shaderManagerInstance.compileAll(gl);
      sm.initProjection({
        near: 0.1,
        far: 1000,
        fov: 60,
      });
      sm.setSettings({ backgroundColor: [1, 1, 1] });
      await sm.setSkyBox(sceneData[0].skyBox);
      await this.getTextureOptions();
      await this.getMeshOptions();
      await this.handleSceneLoad(sm, sceneData);

      sm.initPickerBuffers();
      sm.render = true;
      this.startRendering(sm);

      document.getElementById("glCanvas").addEventListener("click", (event) => {
        const rect = sm.canvas.getBoundingClientRect();
        sm.picker.mouseX = event.clientX - rect.left;
        sm.picker.mouseY = event.clientY - rect.top;
        sm.picker.active = true;
        sm.render = true;
      });

      this.setState({ sceneManager: sm });
    } catch (err) {
      console.error(err);
    }
  };

  startRendering = (sm) => {
    let then = 0.0;
    const handleSelect = this.handleObjectSelect.bind(this);

    function render(now) {
      now *= 0.001; // convert to seconds
      const deltaTime = now - then;
      then = now;

      sm.deltaTime = deltaTime;

      // Ensure scene is fully loaded before rendering
      if (
        sm.objectsToLoad <= Object.entries(sm.getObjects()).length &&
        sm.skyBox.loaded
      ) {
        if (!sm.initialRender) {
          sm.drawFullScene(handleSelect);
          sm.initialRender = true;
        }

        let camera = sm.getActiveCamera();
        if (!sm.gameStarted) {
          camera.init(sm.getCanvas());
          sm.gameStarted = true;
        }

        // Handle camera movement with keyboard inputs
        if (camera.keyboard["w"]) camera.moveForward(deltaTime);
        if (camera.keyboard["s"]) camera.moveBackward(deltaTime);
        if (camera.keyboard["a"]) camera.moveLeft(deltaTime);
        if (camera.keyboard["d"]) camera.moveRight(deltaTime);

        // Update camera front vector based on mouse movements
        if (camera.move) {
          let front = vec3.fromValues(0, 0, 0);
          camera.yaw += camera.rateX * camera.sensitivity;
          camera.pitch += camera.rateY * camera.sensitivity;

          // Clamp the pitch to prevent camera flipping
          if (camera.pitch > 89) {
            camera.pitch = 89;
          }
          if (camera.pitch < -89) {
            camera.pitch = -89;
          }

          // Calculate the new front vector
          front[0] =
            Math.cos(Common.toRadians(camera.yaw)) *
            Math.cos(Common.toRadians(camera.pitch));
          front[1] = Math.sin(Common.toRadians(camera.pitch));
          front[2] =
            Math.sin(Common.toRadians(camera.yaw)) *
            Math.cos(Common.toRadians(camera.pitch));

          vec3.normalize(camera.front, front);
        }

        let keyMove = Object.values(camera.keyboard).includes(true);
        // Draw the scene if there are updates from camera movement or user input
        if (camera.move || keyMove || sm.render) {
          sm.drawFullScene(handleSelect);
        }
      } else {
        sm.drawFullScene(handleSelect);
      }

      // Request another frame for continuous rendering
      requestAnimationFrame(render);
    }

    // Start rendering loop
    requestAnimationFrame(render);
  };

  handleSceneSave = () => {
    const { sceneManager } = this.state;

    if (sceneManager) {
      let sceneData = Common.createSceneFile(sceneManager);
      sceneData[0].settings.camera = sceneManager.getActiveCamera();
      localStorage.setItem("currSceneData", JSON.stringify(sceneData));
    }
  };

  handleSceneDownload = async () => {
    const { sceneManager } = this.state;

    if (sceneManager) {
      let sceneData = Common.createSceneFile(sceneManager);
      sceneData[0].settings.camera = sceneManager.getActiveCamera();

      let stringData = JSON.stringify(sceneData);
      let aDownload = document.createElement("a");
      let sceneName = localStorage.getItem("jwt")
        ? localStorage.getItem("currSceneName")
        : "scene";

      aDownload.href =
        "data:text/json;charset=utf-8," + encodeURIComponent(stringData);
      aDownload.download = `${sceneName}.json`;
      aDownload.click();
      aDownload.remove();
    }
  };

  handleCloneObject = async () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    if (sceneManager) {
      const selectedObject = sceneManager.getSelectedObject();
      if (selectedObject instanceof PointLight) {
        return;
      }
      if (selectedObject) {
        await sceneManager.cloneObject(selectedObject);
        this.setState({ sceneManager });
      }
    }
  };

  getPosition() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return selectedObject.position;
      }
      return selectedObject.model.position;
    }
  }

  handlePositionChange(axis, value) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return [0, 0, 0];
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        selectedObject.position[axis] = value;
      } else {
        selectedObject.model.position[axis] = value;
      }
    }
    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  getRotation() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return [0, 0, 0];
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return [0, 0, 0];
      }
      return selectedObject.getRotationValues();
    }
  }

  handleRotationChange(axis, value, angle) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const axisIndices = { x: 0, y: 1, z: 2 };
    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return [0, 0, 0];
    }

    let rotationValues = selectedObject.getRotationValues();
    const currentValue = rotationValues[axisIndices[axis]];

    const delta = value - currentValue;
    if (delta !== 0) {
      selectedObject.rotate(axis, delta * angle);
      rotationValues[axisIndices[axis]] = value;
      selectedObject.setRotationValues(rotationValues);
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  getScale() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return [0, 0, 0];
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return [0, 0, 0];
      }
      return selectedObject.model.scale;
    }
  }

  handleScaleChange(value, axis) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return [0, 0, 0];
    }

    const scaleValues = selectedObject.getScaleValues();
    scaleValues[axis] = value;

    selectedObject.model.scale[axis] = value;
    selectedObject.setScaleValues(scaleValues);

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  getColour() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return getLightColor(selectedObject);
      }
      return getObjectDiffuseColor(selectedObject);
    }
  }

  handleColourChange(event) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      handleLightColorChange(sceneManager, event, selectedObject);
    } else {
      handleObjectColourChange(sceneManager, event, selectedObject);
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  getAlpha() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return 0;
      }
      return getObjectAlpha(selectedObject);
    }
  }

  handleAlphaChange(event) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return "#000000";
    }

    handleObjectAlphaChange(sceneManager, event, selectedObject);

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  getN() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return 0;
      }
      return selectedObject.material.n;
    }
  }

  handleNChange(event) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return 0;
    }

    handleObjectNValueChange(sceneManager, event, selectedObject);

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  handleShaderChange = async (type) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return 0;
    }

    if (selectedObject) {
      selectedObject.material.shaderType = type;
      await selectedObject.reset();
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  getShader(type) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject instanceof PointLight) {
      return false;
    }

    if (selectedObject) {
      if (selectedObject.material.shaderType == type) {
        return true;
      }
    }
    return false;
  }

  getStrength() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return selectedObject.strength;
      }
    }
  }

  handleStrengthChange = async (value) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        selectedObject.strength = value;
      }
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  getParentOptions() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      let objectList = [];
      let objectKeys = Object.keys(sceneManager.getObjects());
      for (let i = 0; i < objectKeys.length; i++) {
        if (objectKeys[i] !== selectedObject.name) {
          objectList.push({
            text: objectKeys[i],
            value: objectKeys[i],
          });
        }
      }
      return objectList;
    }
  }

  getParent() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      return selectedObject.parent;
    }
  }

  handleParentChange(value) {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      selectedObject.parent = value;
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  }

  handleObjectDelete = async () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      deleteObject(sceneManager);
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  handleSkyBoxToggle = async (event) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    if (event.target.checked) {
      sceneManager.setSkyBoxOn(true);
      this.skyBoxOn = true;
    } else {
      sceneManager.setSkyBoxOn(false);
      this.skyBoxOn = false;
    }

    sceneManager.refresh();
    sceneManager.drawFullScene(sceneManager);
    this.setState({ sceneManager });
  };

  handleBackgroundColourChange = async (event) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const colour = Common.hexToRGB(event.target.value);
    sceneManager.setSettings({ backgroundColor: colour });

    sceneManager.refresh();
    sceneManager.drawFullScene(sceneManager);
    this.setState({ sceneManager });
  };

  getBackgroundColour = () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    let colour = Common.RGBToHex(
      sceneManager.getSceneSettings().backgroundColor,
    );
    return colour;
  };

  getSkybox = () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const sb = sceneManager.getSkyBox();
    return sb;
  };

  handleSkyBoxChange = async (event) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    await sceneManager.setSkyBox(event.target.value);

    sceneManager.refresh();
    sceneManager.drawFullScene(sceneManager);
    this.setState({ sceneManager });
  };

  handleTemplateDownload = async () => {
    window.open(
      "https://s3-us-west-2.amazonaws.com/constructionyard.ca/refineryTemplate.zip",
      "_blank",
    );
  };

  openAddObjectModal = () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    this.addModalIsOpen = true;

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  closeAddObjectModal = () => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    this.addModalIsOpen = false;
    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  addObject = async (objectData) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const objectName = objectData.name;
    const objectType = objectData.type;
    const customMesh = objectData.customMesh;

    if (
      Object.keys(sceneManager.getObjects()).includes(objectName) ||
      Object.keys(sceneManager.getPointLights()).includes(objectName)
    ) {
      alert("Object name already exists! Please enter a valid object name!");
    } else {
      if (objectType === "light") {
        await sceneManager.addPointLight({
          name: objectName,
          colour: [1.0, 1.0, 1.0],
          position: [0.0, 0.0, 0.0],
          strength: 1,
          quadratic: 0.035,
          linear: 0.09,
          constant: 1,
          nearPlane: 0.5,
          farPlane: 100,
          shadow: 0,
        });
      } else if (objectType === "mesh") {
        const mid = await this.meshIDs[this.meshOptions.indexOf(customMesh)];
        await sceneManager.loadObject({
          name: objectName,
          position: [0, 0, 0],
          scale: [5, 5, 5],
          material: {
            diffuse: [1.0, 1.0, 1.0],
            ambient: [0.5, 0.5, 0.5],
            specular: [0.5, 0.5, 0.5],
            n: 32,
            alpha: 1,
            shaderType: 1,
          },
          mid: mid,
          type: objectType.toLowerCase(),
        });
      } else {
        await sceneManager.loadObject({
          name: objectName,
          position: [0, 0, 0],
          scale: [5, 5, 5],
          material: {
            diffuse: [1.0, 1.0, 1.0],
            ambient: [0.5, 0.5, 0.5],
            specular: [0.5, 0.5, 0.5],
            n: 32,
            alpha: 1,
            shaderType: 1,
          },
          type: objectType.toLowerCase(),
        });
      }
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
    this.closeAddObjectModal();
  };

  getDiffuseTexture() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return "";
      }
      return selectedObject.model.diffuseTexture;
    }
  }

  handleDiffuseTextureChange = async (value) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return "";
      }
      selectedObject.model.diffuseTexture = value;
      if (
        !(DIFFUSE_TEXTURES.includes(value) && NORMAL_TEXTURES.includes(value))
      ) {
        selectedObject.model.tid =
          await this.textureIDs[this.textureOptions.indexOf(value)];
      }
      await selectedObject.reset();
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  getNormalTexture() {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return "";
      }
      this.getTextureOptions();
      return selectedObject.model.normalTexture;
    }
  }

  handleNormalTextureChange = async (event) => {
    const { sceneManager } = this.state;
    if (!sceneManager) {
      return;
    }

    const selectedObject = sceneManager.getSelectedObject();
    if (selectedObject) {
      if (selectedObject instanceof PointLight) {
        return "";
      }
      selectedObject.model.normalTexture = event.target.value;
      await selectedObject.reset();
    }

    sceneManager.refresh();
    this.setState({ sceneManager });
  };

  getMeshOptions = async () => {
    try {
      const token = localStorage.getItem("jwt");

      const response = await fetch(`${BACKEND_URL}/meshes/list`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("List Meshes Failed");
      }

      const data = await response.json();
      this.meshOptions = data.map((mesh) => mesh.mesh_name);
      this.meshIDs = data.map((mesh) => mesh.mid);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  getTextureOptions = async () => {
    try {
      const token = localStorage.getItem("jwt");

      const response = await fetch(`${BACKEND_URL}/textures/list`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("List Textures Failed");
      }

      const data = await response.json();
      this.textureOptions = data.map((texture) => texture.texture_name);
      this.textureIDs = data.map((texture) => texture.tid);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  render() {
    const { sceneManager } = this.state;
    const selectedObject = sceneManager
      ? sceneManager.getSelectedObject()
      : undefined;
    const position = this.getPosition();
    const rotation = this.getRotation();
    const scale = this.getScale();

    return (
      <div>
        <AddObjectModal
          isOpen={this.addModalIsOpen}
          closeModal={this.closeAddObjectModal}
          addObject={this.addObject}
          meshOptions={this.meshOptions}
        />
        <div style={{ height: "100vh" }}>
          <EditSceneSubHeader
            saveFunction={this.handleSceneSave}
            skyBoxOn={this.skyBoxOn}
            handleSkyBoxToggle={this.handleSkyBoxToggle}
            handleTemplateDownload={this.handleTemplateDownload}
            handleSceneDownload={this.handleSceneDownload}
            getBackgroundColour={this.getBackgroundColour}
            handleBackgroundColourChange={this.handleBackgroundColourChange}
            getSkybox={this.getSkybox}
            handleSkyBoxChange={this.handleSkyBoxChange}
            getTextureOptions={this.getTextureOptions}
            textureOptions={this.textureOptions}
            textureIDs={this.textureIDs}
            getMeshOptions={this.getMeshOptions}
            meshOptions={this.meshOptions}
            meshIDs={this.meshIDs}
          />
          <div className="ui grid" style={{ height: "80%", margin: 0 }}>
            <div className="row" style={{ height: "100%" }}>
              <div className="ten wide column" style={{ height: "100%" }}>
                <div
                  className="ui segment"
                  style={{ height: "100%", overflow: "hidden" }}
                >
                  <canvas
                    id="glCanvas"
                    ref={this.canvasRef}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>
              <div className="six wide column">
                <div
                  className="ui segment"
                  style={{
                    minHeight: "100%",
                    maxHeight: "100px",
                    marginBottom: "20px",
                  }}
                >
                  <div className="ui grid" style={{ maxHeight: "85%" }}>
                    <div
                      className="four wide left attached column"
                      style={{
                        padding: "20px",
                        paddingRight: "0px",
                        paddingLeft: "0px",
                      }}
                    >
                      <div className="row">
                        <h1
                          className="ui teal header"
                          style={{ paddingLeft: "20px" }}
                        >
                          Objects
                        </h1>
                        <div className="ui divider"></div>
                        <div
                          style={{
                            height: "40vh",
                            maxHeight: "40vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          {sceneManager && sceneManager.getObjects() ? (
                            Object.keys(sceneManager.getObjects()).map(
                              (item, index) => {
                                const isSelected =
                                  sceneManager.getSelectedObject() &&
                                  sceneManager.getSelectedObject().name ===
                                    item;
                                return (
                                  <button
                                    key={index}
                                    className={`ui button ${isSelected ? "teal" : "white"}`}
                                    style={{
                                      width: "100%",
                                      marginBottom: "8px",
                                      boxShadow: "none",
                                      fontSize: "12px",
                                      padding: "5px",
                                    }}
                                    onClick={() =>
                                      this.handleObjectSelect(
                                        sceneManager.getObject(item),
                                      )
                                    }
                                  >
                                    {item}
                                  </button>
                                );
                              },
                            )
                          ) : (
                            <p>No objects</p>
                          )}
                          <button
                            className="ui mini green button"
                            style={{
                              width: "100%",
                              marginBottom: "8px",
                              boxShadow: "none",
                            }}
                            onClick={() => {
                              this.openAddObjectModal();
                            }}
                          >
                            <i className="plus icon"></i>
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <h1
                          className="ui teal  header"
                          style={{ paddingLeft: "20px" }}
                        >
                          Lights
                        </h1>
                        <div className="ui divider"></div>
                        <div
                          style={{
                            height: "100px",
                            maxHeight: "100px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                          }}
                        >
                          {sceneManager && sceneManager.pointLights ? (
                            Object.entries(sceneManager.getPointLights()).map(
                              ([key, value], index) => {
                                const isSelected =
                                  sceneManager.getSelectedObject() &&
                                  sceneManager.getSelectedObject().name ===
                                    value.name;
                                return (
                                  <button
                                    key={index}
                                    className={`ui button ${isSelected ? "teal" : "white"}`}
                                    style={{
                                      width: "100%",
                                      fontSize: "12px",
                                      padding: "5px",
                                      marginBottom: "8px",
                                    }}
                                    onClick={() =>
                                      this.handleLightSelect(value)
                                    }
                                  >
                                    {value.name}
                                  </button>
                                );
                              },
                            )
                          ) : (
                            <p>No lights</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="twelve wide left attached column"
                      style={{
                        maxHeight: "60%",
                        padding: "20px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      <h1 className="ui teal centered header">Inspector</h1>
                      <div className="ui divider"></div>

                      {selectedObject ? (
                        <div
                          className="ui center aligned grid"
                          style={{
                            maxHeight: "61vh",
                            overflowY: "auto",
                            overflowX: "hidden",
                            marginLeft: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          <div className="twelve wide column">
                            <div style={{ userSelect: "none" }}>
                              <div
                                className="row"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="ui teal inverted segment">
                                  <h2
                                    className="ui header white"
                                    style={{
                                      fontSize: "18px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {selectedObject.name}
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: "20px" }}
                              >
                                <h2 className="ui header teal">Parent</h2>
                                <select
                                  className="ui selection dropdown"
                                  style={{ padding: "5px", color: "teal" }}
                                  value={this.getParent()}
                                  onChange={(e) =>
                                    this.handleParentChange(e.target.value)
                                  }
                                >
                                  <option value="" style={{ color: "teal" }}>
                                    None
                                  </option>
                                  {this.getParentOptions().map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      style={{ color: "teal" }}
                                    >
                                      {option.value}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: "20px" }}
                              >
                                <h2 className="ui header teal">Position</h2>
                                <div className="ui grid">
                                  <div className="three column row">
                                    <div
                                      className="left aligned column"
                                      style={{ padding: "5px" }}
                                    >
                                      <div className="ui labeled input">
                                        <div
                                          className="ui teal label"
                                          style={{ padding: "7px" }}
                                        >
                                          X
                                        </div>
                                        <input
                                          placeholder="0"
                                          value={position[0]}
                                          onChange={(e) => {
                                            this.handlePositionChange(
                                              0,
                                              parseInt(e.target.value),
                                            );
                                          }}
                                          step="1"
                                          type="number"
                                          style={{
                                            width: "33%",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="center aligned column"
                                      style={{ padding: "5px" }}
                                    >
                                      <div className="ui labeled input">
                                        <div
                                          className="ui teal label"
                                          style={{ padding: "7px" }}
                                        >
                                          Y
                                        </div>
                                        <input
                                          placeholder="0"
                                          value={position[1]}
                                          onChange={(e) => {
                                            this.handlePositionChange(
                                              1,
                                              parseInt(e.target.value),
                                            );
                                          }}
                                          step="1"
                                          type="number"
                                          style={{
                                            width: "33%",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                    <div
                                      className="right aligned column"
                                      style={{ padding: "5px" }}
                                    >
                                      <div className="ui labeled input">
                                        <div
                                          className="ui teal label"
                                          style={{ padding: "7px" }}
                                        >
                                          Z
                                        </div>
                                        <input
                                          placeholder="0"
                                          value={position[2]}
                                          onChange={(e) => {
                                            this.handlePositionChange(
                                              2,
                                              parseInt(e.target.value),
                                            );
                                          }}
                                          step="1"
                                          type="number"
                                          style={{
                                            width: "33%",
                                            padding: "5px",
                                            margin: "0px",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Rotation</h2>
                                  <div className="ui grid">
                                    <div className="three column row">
                                      <div
                                        className="left aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            X
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={rotation[0]}
                                            onChange={(e) => {
                                              this.handleRotationChange(
                                                "x",
                                                parseInt(e.target.value),
                                                0.261799,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="center aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            Y
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={rotation[1]}
                                            onChange={(e) => {
                                              this.handleRotationChange(
                                                "y",
                                                parseInt(e.target.value),
                                                0.261799,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                      <div
                                        className="right aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            Z
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={rotation[2]}
                                            onChange={(e) => {
                                              this.handleRotationChange(
                                                "z",
                                                parseInt(e.target.value),
                                                0.261799,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Scale</h2>
                                  <div className="ui grid">
                                    <div className="three column row">
                                      <div
                                        className="left aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            X
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={scale[0]}
                                            onChange={(e) => {
                                              this.handleScaleChange(
                                                parseInt(e.target.value),
                                                0,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="center aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            Y
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={scale[1]}
                                            onChange={(e) => {
                                              this.handleScaleChange(
                                                parseInt(e.target.value),
                                                1,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                      <div
                                        className="right aligned column"
                                        style={{ padding: "5px" }}
                                      >
                                        <div className="ui labeled input">
                                          <div
                                            className="ui teal label"
                                            style={{ padding: "7px" }}
                                          >
                                            Z
                                          </div>
                                          <input
                                            placeholder="0"
                                            value={scale[2]}
                                            onChange={(e) => {
                                              this.handleScaleChange(
                                                parseInt(e.target.value),
                                                2,
                                              );
                                            }}
                                            step="1"
                                            type="number"
                                            style={{
                                              width: "33%",
                                              padding: "5px",
                                              margin: "0px",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">
                                    Diffuse Colour
                                  </h2>
                                  <input
                                    type="color"
                                    value={this.getColour()}
                                    onChange={(e) => this.handleColourChange(e)}
                                  ></input>
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Alpha</h2>
                                  <input
                                    type="range"
                                    min="0.0"
                                    max="1.0"
                                    step="0.05"
                                    value={this.getAlpha()}
                                    onChange={(e) => this.handleAlphaChange(e)}
                                    style={{
                                      accentColor: "#00b5ad",
                                      backgroundColor: "white",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Shininess</h2>
                                  <div className="ui input">
                                    <input
                                      type="number"
                                      min="0.0"
                                      max="10000.0"
                                      placeholder="0"
                                      value={this.getN()}
                                      onChange={(e) => this.handleNChange(e)}
                                    />
                                  </div>
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Shaders</h2>
                                  <div className="ui teal inverted segment">
                                    <form className="ui teal inverted form">
                                      <fieldset
                                        className="ui left aligned middle aligned grid"
                                        style={{
                                          border: "0px",
                                          padding: "10px",
                                        }}
                                      >
                                        <div className="field">
                                          <div className="ui radio checkbox">
                                            <input
                                              type="radio"
                                              name="frequency"
                                              checked={this.getShader(3)}
                                              onChange={() => {
                                                this.handleShaderChange(3);
                                              }}
                                            />
                                            <label>Diffuse Texture</label>
                                          </div>
                                        </div>
                                        <div className="field">
                                          <div className="ui radio checkbox">
                                            <input
                                              type="radio"
                                              name="frequency"
                                              checked={this.getShader(4)}
                                              onChange={() => {
                                                this.handleShaderChange(4);
                                              }}
                                            />
                                            <label>Normal Texture</label>
                                          </div>
                                        </div>
                                        <div className="field">
                                          <div className="ui radio checkbox">
                                            <input
                                              type="radio"
                                              name="frequency"
                                              checked={this.getShader(6)}
                                              onChange={() => {
                                                this.handleShaderChange(6);
                                              }}
                                            />
                                            <label>Environment Map</label>
                                          </div>
                                        </div>
                                        <div className="field">
                                          <div className="ui radio checkbox">
                                            <input
                                              type="radio"
                                              name="frequency"
                                              checked={this.getShader(1)}
                                              onChange={() => {
                                                this.handleShaderChange(1);
                                              }}
                                            />
                                            <label>No Texture</label>
                                          </div>
                                        </div>
                                      </fieldset>
                                    </form>
                                  </div>
                                </div>
                              )}
                              {!(selectedObject instanceof PointLight) && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Textures</h2>
                                  <h4 className="ui header teal">
                                    Diffuse Texture
                                  </h4>
                                  <select
                                    className="ui selection dropdown"
                                    style={{ padding: "5px", color: "teal" }}
                                    value={this.getDiffuseTexture()}
                                    onChange={(e) => {
                                      this.handleDiffuseTextureChange(
                                        e.target.value,
                                      );
                                    }}
                                  >
                                    {[
                                      ...DIFFUSE_TEXTURES,
                                      ...this.textureOptions,
                                    ].map((option) => (
                                      <option
                                        key={option}
                                        value={option}
                                        style={{ color: "teal" }}
                                      >
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <h4 className="ui header teal">
                                    Normal Texture
                                  </h4>
                                  <select
                                    className="ui selection dropdown"
                                    style={{ padding: "5px", color: "teal" }}
                                    value={this.getNormalTexture()}
                                    onChange={(e) => {
                                      this.handleNormalTextureChange(e);
                                    }}
                                  >
                                    {[...NORMAL_TEXTURES].map((option) => (
                                      <option
                                        key={option}
                                        value={option}
                                        style={{ color: "teal" }}
                                      >
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              {selectedObject instanceof PointLight && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">Strength</h2>
                                  <div className="ui input">
                                    <input
                                      type="number"
                                      min="0.0"
                                      max="10000.0"
                                      placeholder="0"
                                      value={this.getStrength()}
                                      onChange={(e) =>
                                        this.handleStrengthChange(
                                          parseInt(e.target.value),
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {selectedObject instanceof PointLight && (
                                <div
                                  className="row"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <h2 className="ui header teal">
                                    Light Colour
                                  </h2>
                                  <input
                                    type="color"
                                    value={this.getColour()}
                                    onChange={(e) => this.handleColourChange(e)}
                                  ></input>
                                </div>
                              )}
                              {/* {selectedObject instanceof PointLight && (<div className="row" style={{marginBottom: '20px'}}>
                              <h2 className="ui header teal">Shadow</h2>
                            </div>)} */}
                              {/* {selectedObject instanceof PointLight && (<div className="row" style={{marginBottom: '20px'}}>
                              <h2 className="ui header teal">Planes</h2>
                            </div>)} */}
                              <div
                                className="row"
                                style={{ marginBottom: "20px" }}
                              >
                                <h2 className="ui header teal">Options</h2>
                                {!(selectedObject instanceof PointLight) && (
                                  <button
                                    className="ui button"
                                    style={{ margin: "10px" }}
                                    onClick={this.handleCloneObject}
                                  >
                                    Clone
                                  </button>
                                )}
                                <button
                                  className="ui red button"
                                  style={{ margin: "10px" }}
                                  onClick={this.handleObjectDelete}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="ui small centered black header">
                          <p>No Object Selected</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Workspace;
