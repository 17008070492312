import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../constants";
import DeleteModal from "./DeleteModal";

const deleteScene = async (sid) => {
  try {
    const token = localStorage.getItem("jwt");

    const response = await fetch(`${BACKEND_URL}/scenes`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({ sid: sid }),
    });

    if (!response.ok) {
      throw new Error("Create Scene Failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const editScene = async (sid, sceneName) => {
  localStorage.setItem("currSID", sid);
  localStorage.setItem("currSceneName", sceneName);
};

const SceneCard = ({ sceneName, sid, created, updated, reload }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const openDeleteModal = () => {
    setIsOpenDelete(true);
  };

  const closeDeleteModal = () => {
    setIsOpenDelete(false);
  };

  const handleEditClick = async () => {
    await editScene(sid, sceneName);
    navigate("/workspace");
  };

  const handleDeleteClick = async (sid) => {
    await deleteScene(sid);
    await reload();
  };

  return (
    <div>
      <div
        className="ui centered card"
        style={{
          transition: "transform 0.3s ease",
          transform: isHovered ? "scale(1.05)" : "scale(1)",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* <img src="/SampleSceneScreenshot.PNG" alt="Construction Yard Logo" className="ui large rounded image"></img> */}
        <div className="content">
          <h2>
            <span
              className="ui teal text"
              style={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {sceneName}
            </span>
          </h2>
          <div className="meta">
            <span className="date">
              Created on{" "}
              <span style={{ fontWeight: "bold", color: "grey" }}>
                {created.split("T")[0]}
              </span>{" "}
              at{" "}
              <span style={{ fontWeight: "bold", color: "grey" }}>
                {created.split("T")[1].split(".")[0]}
              </span>
            </span>
          </div>
          {/* <div className="ui divider"></div> */}
          <div className="meta">
            <span className="date">
              Last updated on{" "}
              <span style={{ fontWeight: "bold", color: "grey" }}>
                {updated == null
                  ? created.split("T")[0]
                  : updated.split("T")[0]}
              </span>{" "}
              at{" "}
              <span style={{ fontWeight: "bold", color: "grey" }}>
                {updated == null
                  ? created.split("T")[1].split(".")[0]
                  : updated.split("T")[1].split(".")[0]}
              </span>
            </span>
          </div>
        </div>
        {/* <div className="ui divider"></div> */}
        <div
          className="ui attached teal button"
          onClick={() => handleEditClick()}
        >
          <i className="pen icon"></i>
          Edit
        </div>
        <div
          className="ui attached bottom red button"
          onClick={openDeleteModal}
        >
          <i className="delete icon"></i>
          Delete
        </div>
      </div>
      <DeleteModal
        isOpen={isOpenDelete}
        closeModal={closeDeleteModal}
        handleDeleteClick={() => {
          handleDeleteClick(sid);
        }}
      />
    </div>
  );
};

export default SceneCard;
