import { vec3 } from "gl-matrix";

class Camera {
  constructor(camera) {
    this.name = camera.name;
    this.position = camera.position ? camera.position : [0.0, 0.0, 0.0];
    this.front = camera.front ? camera.front : [0.0, 0.0, 1.0];
    this.up = camera.up ? camera.up : [0.0, 1.0, 0.0];
    this.pitch = camera.pitch ? camera.pitch : 0;
    this.yaw = camera.yaw ? camera.yaw : 90;
    this.roll = camera.roll ? camera.roll : 0;
    this.moveSpeed = 10; //const
    this.move = false;
    this.canvasFocused = false;
    this.rateX = 0;
    this.rateY = 0;
    this.keyboard = {};
    this.viewMatrix = null;
    this.sensitivity = 0.4;
  }

  getCameraName() {
    return this.name;
  }

  setCameraName(value) {
    this.name = value;
  }

  getViewMatrix() {
    return this.viewMatrix;
  }

  setViewMatrix(mat) {
    this.viewMatrix = mat;
  }

  // TODO - Create a de-init func to remove these listeners for this camera
  init(canvas) {
    canvas.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false,
    );

    canvas.addEventListener("mousemove", (event) => {
      this.canvasFocused = true;
      //handle right click
      if (event.buttons == 2) {
        this.move = true;
        this.rateX = event.movementX;
        this.rateY = -event.movementY;
      }
    });

    canvas.addEventListener("mouseup", () => {
      this.move = false;
      this.rateX = 0;
      this.rateY = 0;
    });

    canvas.addEventListener("mouseout", () => {
      this.canvasFocused = false;
      this.move = false;
      this.rateX = 0;
      this.rateY = 0;
    });

    window.addEventListener("keypress", (event) => {
      if (this.canvasFocused) {
        switch (event.code) {
          case "KeyW":
            this.keyboard[event.key] = true;
            break;

          case "KeyS":
            this.keyboard[event.key] = true;
            break;

          case "KeyA":
            this.keyboard[event.key] = true;
            break;

          case "KeyD":
            this.keyboard[event.key] = true;
            break;

          default:
            break;
        }
      }
    });

    window.addEventListener("keyup", (event) => {
      switch (event.code) {
        case "KeyW":
          this.keyboard[event.key] = false;
          break;

        case "KeyS":
          this.keyboard[event.key] = false;
          break;

        case "KeyA":
          this.keyboard[event.key] = false;
          break;

        case "KeyD":
          this.keyboard[event.key] = false;
          break;
        default:
          break;
      }
    });
  }

  moveForward(deltaTime) {
    let forwardVector = vec3.create(),
      cameraPositionVector = vec3.create();
    //forward vector from the viewmatrix
    forwardVector = vec3.fromValues(
      -this.viewMatrix[2],
      -this.viewMatrix[6],
      -this.viewMatrix[10],
    );
    vec3.scale(forwardVector, forwardVector, deltaTime * this.moveSpeed);

    cameraPositionVector = vec3.fromValues(
      this.position[0],
      this.position[1],
      this.position[2],
    );
    vec3.add(cameraPositionVector, cameraPositionVector, forwardVector);
    this.position = [
      cameraPositionVector[0],
      cameraPositionVector[1],
      cameraPositionVector[2],
    ];
  }

  moveBackward(deltaTime) {
    let forwardVector = vec3.create(),
      cameraPositionVector = vec3.create();
    //forward vector from the viewmatrix
    forwardVector = vec3.fromValues(
      this.viewMatrix[2],
      this.viewMatrix[6],
      this.viewMatrix[10],
    );
    vec3.scale(forwardVector, forwardVector, deltaTime * this.moveSpeed);
    cameraPositionVector = vec3.fromValues(
      this.position[0],
      this.position[1],
      this.position[2],
    );
    vec3.add(cameraPositionVector, cameraPositionVector, forwardVector);
    this.position = [
      cameraPositionVector[0],
      cameraPositionVector[1],
      cameraPositionVector[2],
    ];
  }

  moveLeft(deltaTime) {
    let forwardVector = vec3.create(),
      sidewaysVector = vec3.create(),
      camerafrontVector = vec3.create(),
      cameraPositionVector = vec3.create();

    sidewaysVector = vec3.create();
    forwardVector = vec3.fromValues(
      this.viewMatrix[2],
      this.viewMatrix[6],
      this.viewMatrix[10],
    );
    vec3.cross(sidewaysVector, forwardVector, this.up);
    vec3.normalize(sidewaysVector, sidewaysVector);
    vec3.scale(sidewaysVector, sidewaysVector, deltaTime * this.moveSpeed);

    camerafrontVector = vec3.fromValues(
      this.front[0],
      this.front[1],
      this.front[2],
    );
    cameraPositionVector = vec3.fromValues(
      this.position[0],
      this.position[1],
      this.position[2],
    );

    vec3.add(camerafrontVector, camerafrontVector, sidewaysVector);
    vec3.add(cameraPositionVector, cameraPositionVector, sidewaysVector);

    this.position = [
      cameraPositionVector[0],
      this.position[1],
      cameraPositionVector[2],
    ];
  }

  moveRight(deltaTime) {
    let forwardVector = vec3.create(),
      sidewaysVector = vec3.create(),
      camerafrontVector = vec3.create(),
      cameraPositionVector = vec3.create();

    sidewaysVector = vec3.create();
    forwardVector = vec3.fromValues(
      -this.viewMatrix[2],
      -this.viewMatrix[6],
      -this.viewMatrix[10],
    );
    vec3.cross(sidewaysVector, forwardVector, this.up);
    vec3.normalize(sidewaysVector, sidewaysVector);
    vec3.scale(sidewaysVector, sidewaysVector, deltaTime * this.moveSpeed);

    camerafrontVector = vec3.fromValues(
      this.front[0],
      this.front[1],
      this.front[2],
    );
    cameraPositionVector = vec3.fromValues(
      this.position[0],
      this.position[1],
      this.position[2],
    );

    vec3.add(camerafrontVector, camerafrontVector, sidewaysVector);
    vec3.add(cameraPositionVector, cameraPositionVector, sidewaysVector);

    this.position = [
      cameraPositionVector[0],
      this.position[1],
      cameraPositionVector[2],
    ];
  }
}

export default Camera;
