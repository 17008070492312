import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SceneCard from "../components/SceneCard";
import SceneSelectSubHeader from "../components/SceneSelectSubHeader";
import { BACKEND_URL } from "../constants";

const loadScenes = async () => {
  try {
    const token = localStorage.getItem("jwt");

    const response = await fetch(`${BACKEND_URL}/scenes/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("List Scenes Failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

function Dashboard() {
  const [names, setNames] = useState(null);
  const [sceneIDs, setSceneIDs] = useState(null);
  const [created, setCreated] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [error, setError] = useState(null);

  const handleCardClick = (sceneName) => {};

  const fetchData = async () => {
    try {
      const res = await loadScenes();

      const sceneNames = res.map((scene) => scene.scene_name);
      setNames(sceneNames);

      const sceneSids = res.map((scene) => scene.sid);
      setSceneIDs(sceneSids);

      const createdDates = res.map((scene) => scene.created);
      setCreated(createdDates);

      const updatedDates = res.map((scene) => scene.updated);
      setUpdated(updatedDates);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      {error ? (
        <h1>404 ERROR</h1>
      ) : names ? (
        <div className="ui center aligned grid" style={{ height: "100vh" }}>
          <div className="eight wide column">
            <SceneSelectSubHeader reload={fetchData} />
            <div className="ui raised segment" style={{ width: "50vw" }}>
              <div
                className="ui three column relaxed padded grid"
                style={{ height: "100%" }}
              >
                {names.length > 0 ? (
                  names.map((sceneName, index) => (
                    <div className="column">
                      <SceneCard
                        key={index}
                        sceneName={sceneName}
                        sid={sceneIDs[index]}
                        created={created[index]}
                        updated={updated[index]}
                        onClick={() => handleCardClick(sceneName)}
                        reload={fetchData}
                      />
                    </div>
                  ))
                ) : (
                  <p style={{ color: "grey" }}>
                    Click{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      "Create"
                    </span>{" "}
                    to make a new scene ...
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p style={{ color: "grey" }}>Loading ...</p>
      )}
    </div>
  );
}

export default Dashboard;
