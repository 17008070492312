import React, { useState } from "react";

import { BACKEND_URL } from "../constants";
import CreateModal from "./CreateModal";
import sampleScene from "./sampleScene.json";

const createScene = async (sceneName) => {
  try {
    const token = localStorage.getItem("jwt");
    const jsonBlob = new Blob([JSON.stringify(sampleScene)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("file", jsonBlob, sceneName);

    const response = await fetch(`${BACKEND_URL}/scenes`, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Create Scene Failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

function SceneSelectSubHeader({ reload }) {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [sceneNameState, setSceneName] = useState("Untitled");

  const openCreateModal = () => {
    setIsOpenCreate(true);
  };

  const closeCreateModal = () => {
    setIsOpenCreate(false);
  };

  const handleCreateClick = async (sceneName) => {
    await createScene(sceneName);
    await reload();
    window.location.reload();
  };

  const handleSceneNameChange = (sceneName) => {
    setSceneName(sceneName);
  };

  return (
    <div
      className="ui raised segment"
      style={{ backgroundColor: "white", position: "relative", width: "50vw" }}
    >
      <div
        className="ui center aligned container"
        style={{ textAlign: "center" }}
      >
        <h2 className="ui header" style={{ color: "#00b5ad", margin: 0 }}>
          <div className="content">
            <h1 style={{ margin: 0 }}>My Scenes</h1>
          </div>
          <div className="ui divider"></div>
          <div className="column centered">
            <div className="ui green button" onClick={openCreateModal}>
              <i className="plus icon"></i>
              Create
            </div>
          </div>
        </h2>
      </div>
      <CreateModal
        isOpen={isOpenCreate}
        closeModal={closeCreateModal}
        handleCreateClick={() => {
          handleCreateClick(sceneNameState);
        }}
        handleSceneNameChange={handleSceneNameChange}
      />
    </div>
  );
}

export default SceneSelectSubHeader;
