import { vec3, mat4 } from "gl-matrix";
import Common from "../CommonRender";
import RenderObject from "./RenderObject";
import shaderManagerInstance, { SHADER_TYPES } from "../ShaderManager";

class CustomObject extends RenderObject {
  constructor(glContext, object) {
    super(object);
    this.state = {};
    this.gl = glContext;
    this.name = object.name;
    this.parent = object.parent;
    this.type = object.type;
    this.loaded = false;
    this.initialTransform = {
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
    };
    this.reflective = object.reflective;
    this.refractionIndex = object.refractionIndex;
    this.material = object.material;
    this.collide = object.collide;
    this.model = {
      vertices: object.model.vertices,
      triangles: object.model.triangles,
      uvs: object.model.uvs,
      normals: object.model.normals,
      diffuseTexture: object.diffuseTexture
        ? object.diffuseTexture
        : "default.jpg",
      normalTexture: object.normalTexture
        ? object.normalTexture
        : "defaultNorm.jpg",
      // texture: object.diffuseTexture ? Common.getTextures(glContext, object.diffuseTexture) : Common.getTextures(glContext, "default.jpg"),
      // textureNorm: object.normalTexture ? Common.getTextures(glContext, object.normalTexture) : Common.getTextures(glContext, "defaultNorm.jpg"),
      buffers: null,
      modelMatrix: mat4.create(),
      position: vec3.fromValues(0.0, 0.0, 0.0),
      rotation: mat4.create(),
      scale: vec3.fromValues(1.0, 1.0, 1.0),
    };
  }

  rotate(axis, angle) {
    if (axis === "x") {
      mat4.rotateX(this.model.rotation, this.model.rotation, angle);
    } else if (axis == "y") {
      mat4.rotateY(this.model.rotation, this.model.rotation, angle);
    } else if (axis == "z") {
      mat4.rotateZ(this.model.rotation, this.model.rotation, angle);
    }
  }

  scaleUVs(scaleVec) {
    let newUVs = [];

    for (let i = 0; i < this.model.uvs.length; i++) {
      if (i % 2 === 0) {
        newUVs.push(this.model.uvs[i] * (scaleVec[0] / vec3.len(scaleVec)));
      } else {
        newUVs.push(this.model.uvs[i] * (scaleVec[2] / vec3.len(scaleVec)));
      }
    }
    return newUVs;
  }

  scale(scaleVec) {
    //model scale
    let xVal = this.model.scale[0];
    let yVal = this.model.scale[1];
    let zVal = this.model.scale[2];
    xVal *= scaleVec[0];
    yVal *= scaleVec[1];
    zVal *= scaleVec[2];

    //need to scale bounding box
    this.model.uvs = this.scaleUVs(scaleVec);
    this.model.scale = vec3.fromValues(xVal, yVal, zVal);
  }

  translate(translateVec) {
    vec3.add(
      this.model.position,
      this.model.position,
      vec3.fromValues(translateVec[0], translateVec[1], translateVec[2]),
    );
  }

  exportValues() {
    return super.exportValues({
      model: {
        vertices: this.model.vertices,
        triangles: this.model.triangles,
        uvs: this.model.uvs,
        normals: this.model.normals,
      },
    });
  }

  lightingShader() {
    return new Promise((resolve, reject) => {
      var shaderProgram;
      var programInfo;

      if (this.material.shaderType === SHADER_TYPES.BASIC) {
        // load in the basic shader here
        const shader = shaderManagerInstance.getShader("basicShader");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_NO_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnNoTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BASIC_DEPTH) {
        const shader = shaderManagerInstance.getShader("basicDepth");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else if (
        this.material.shaderType === SHADER_TYPES.BLINN_NORMAL_TEXTURE
      ) {
        const shader = shaderManagerInstance.getShader("blinnDiffuseAndNormal");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(
          this.gl,
          shaderProgram,
          shader.uniforms,
          shader.attribs,
        );
        this.programInfo = programInfo;
        resolve();
      } else {
        reject("No shader found for this material type");
      }
    });
  }

  initBuffers() {
    return new Promise((resolve) => {
      //create vertices, normal and indicies arrays
      const positions = new Float32Array(this.model.vertices);
      const normals = new Float32Array(this.model.normals);
      const indices = this.model.triangles
        ? new Uint16Array(this.model.triangles)
        : null;
      const textureCoords = new Float32Array(this.model.uvs);
      const bitangents = new Float32Array(this.model.bitangents);
      const tangents = new Float32Array(this.model.tangents);

      var vertexArrayObject = this.gl.createVertexArray();
      this.gl.bindVertexArray(vertexArrayObject);

      this.buffers = {
        vao: vertexArrayObject,
        attributes: {
          position:
            this.programInfo.attribLocations.vertexPosition != null
              ? this.initPositionAttribute(this.gl, this.programInfo, positions)
              : null,
          normal:
            this.programInfo.attribLocations.vertexNormal != null
              ? this.initNormalAttribute(this.gl, this.programInfo, normals)
              : null,
          uv:
            this.programInfo.attribLocations.vertexUV != null
              ? this.initTextureCoords(this.gl, this.programInfo, textureCoords)
              : null,
          bitangents:
            this.programInfo.attribLocations.vertexBitangent != null
              ? this.initBitangentBuffer(this.gl, this.programInfo, bitangents)
              : null,
          tangents:
            this.programInfo.attribLocations.vertexTangent != null
              ? this.initBitangentBuffer(this.gl, this.programInfo, tangents)
              : null,
        },
        indicies: indices ? this.initIndexBuffer(this.gl, indices) : null,
        numVertices: indices ? indices.length : this.model.vertices.length,
      };
      resolve();
    });
  }

  reset() {
    return new Promise((resolve, reject) => {
      this.setup(true)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setup(reset) {
    return new Promise((resolve, reject) => {
      this.getTextures(this.gl, this.model.diffuseTexture)
        .then((data) => {
          this.model.texture = data;
          this.getTextures(this.gl, this.model.normalTexture)
            .then((data) => {
              this.model.textureNorm = data;
              this.calculateCentroid(this.model.vertices);
              this.lightingShader().then(() => {
                this.initBuffers().then(() => {
                  if (!reset) {
                    this.scale(this.initialTransform.scale);
                    this.model.position = this.initialTransform.position;

                    if (this.initialTransform.rotation) {
                      this.model.rotation = this.initialTransform.rotation;
                    }
                  }
                  this.loaded = true;
                  resolve();
                });
              });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete() {
    Object.keys(this.buffers.attributes).forEach((key) => {
      this.gl.deleteBuffer(this.buffers.attributes[key]);
    });
    this.gl.deleteBuffer(this.buffers.indicies);
  }
}

export default CustomObject;
