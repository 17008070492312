import Light from "./Light";

class PointLight extends Light {
  constructor(glContext, object) {
    super(glContext, object);
    this.type = "pointLight";
  }
}

export default PointLight;
