import { mat4, vec4 } from "gl-matrix";
import { BACKEND_URL } from "../constants";
import Camera from "./Camera";
import Common from "./CommonRender";
import Renderer from "./Renderer";
import PointLight from "./lighting/PointLight";
import Cube from "./objects/Cube";
import CustomObject from "./objects/CustomObject";
import Mesh from "./objects/Mesh";
import Plane from "./objects/Plane";
import SkyBox from "./objects/SkyBox";
import Sphere from "./objects/Sphere";

export class SceneManager {
  constructor() {
    this.objects = {};
    this.pointLights = {};
    this.numPointLights = 0;
    this.sceneSettings = {};
    this.cameras = {};
    this.canvas = null;
    this.dimensions = { width: 0, height: 0 };
    this.gl = null;
    this.activeCamera = null;
    this.render = false;
    this.objectsToLoad = 0;
    this.initialRender = false;
    this.gameStarted = false;
    this.projectionMatrix = mat4.create();
    this.fov = 60; //in degrees
    this.far = 10000;
    this.near = 0.1;
    this.picker = {
      mouseX: 0,
      mouseY: 0,
      active: false,
    };
    this.selectedObject = null;
    this.getObject = this.getObject.bind(this);
    this.renderer = new Renderer({ getObject: this.getObject });
    this.skyBox = new SkyBox(this.gl, {});
    this.skyBoxOn = false;
    this.skyBoxPath = "Space";
  }

  getCanvas() {
    return this.canvas;
  }

  setCanvas(canvas) {
    this.canvas = canvas;
    this.renderer.canvas = canvas;
  }

  getGLContext() {
    return this.gl;
  }

  setGLContext(context) {
    this.gl = context;
    this.renderer.gl = context;
    this.skyBox.gl = context;
  }

  async setSkyBox(skyBox) {
    this.skyBoxPath = skyBox;
    await this.skyBox.setup(skyBox);
  }

  getSkyBox() {
    return this.skyBoxPath;
  }

  setSkyBoxOn(b) {
    this.skyBoxOn = b;
  }

  // Objects
  getObjects() {
    return this.objects;
  }

  getObjectsNames() {
    return Object.keys(this.objects);
  }

  getObject(name) {
    return this.objects[name];
  }

  addObject(object) {
    this.objects[object.name] = object;
    this.objectsToLoad++;
    this.refresh();
    this.selectedObject = object;
  }

  loadMesh = async (mid) => {
    try {
      const token = localStorage.getItem("jwt");

      const response = await fetch(`${BACKEND_URL}/meshes/${mid}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Load Mesh Failed");
      }

      const blob = await response.blob();
      const meshDataURL = URL.createObjectURL(blob);
      return meshDataURL;
    } catch (error) {
      console.error("Error loading mesh:", error);
    }
  };

  async loadObject(newObject) {
    //get the value of the type
    let type = newObject.type;
    let defaultMat = {
      diffuse: [0.5882, 0.5882, 0.5882],
      ambient: [0.3, 0.3, 0.3],
      specular: [0.5, 0.5, 0.5],
      n: 10.000002,
      shaderType: 1,
      alpha: 1,
    };
    let additionalArgs = { ...newObject };
    let tempObject;

    //loading an existing object
    if (newObject.position) {
      if (type === "cube" && newObject.name) {
        tempObject = new Cube(this.gl, {
          name: newObject.name,
          type: "cube",
          material: newObject.material,
          scale: newObject.scale,
          position: newObject.position,
          rotation: newObject.rotation,
          diffuseTexture: newObject.diffuseTexture,
          normalTexture: newObject.normalTexture,
          tid: newObject.tid,
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      } else if (type === "plane" && newObject.name) {
        tempObject = new Plane(this.gl, {
          name: newObject.name,
          type: "plane",
          material: newObject.material,
          scale: newObject.scale,
          position: newObject.position,
          rotation: newObject.rotation,
          diffuseTexture: newObject.diffuseTexture,
          normalTexture: newObject.normalTexture,
          tid: newObject.tid,
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      } else if (type === "bunny" && newObject.name) {
        let defaultOBJName = "bunny.obj";
        let meshDetails = await Common.parseOBJFileToJSON(
          "/models/" + defaultOBJName,
          {},
        );
        tempObject = new Mesh(
          this.gl,
          {
            name: newObject.name,
            material: newObject.material ? newObject.material : defaultMat,
            modelName: "bunny.obj",
            scale: newObject.scale,
            rotation: newObject.rotation ? newObject.rotation : null,
            position: newObject.position,
            type: "mesh",
            diffuseTexture: newObject.diffuseTexture,
            normalTexture: newObject.normalTexture,
            tid: newObject.tid,
          },
          meshDetails,
        );
        await tempObject.setup();
      } else if (type === "mesh" && newObject.name) {
        // If there is a model id, we try and fetch that first, then we check if the model was uploaded and lastly we just default to the bunny
        if (newObject.mid) {
          let meshDataURL = await this.loadMesh(newObject.mid);
          let meshDetails = await Common.parseOBJFileToJSON(meshDataURL, {});
          tempObject = new Mesh(
            this.gl,
            {
              name: newObject.name,
              material: newObject.material ? newObject.material : defaultMat,
              modelName: "bunny.obj",
              scale: newObject.scale,
              rotation: newObject.rotation ? newObject.rotation : null,
              position: newObject.position,
              type: "mesh",
              diffuseTexture: newObject.diffuseTexture,
              normalTexture: newObject.normalTexture,
              tid: newObject.tid,
              mid: newObject.mid,
            },
            meshDetails,
          );
          await tempObject.setup();
        } else if (newObject.uploaded) {
          let meshDetails = await Common.parseOBJFileToJSON(
            null,
            {},
            newObject.uploaded.initGeometry,
          );
          tempObject = new Mesh(
            this.gl,
            {
              name: newObject.name,
              material: newObject.material ? newObject.material : defaultMat,
              modelName: newObject.uploaded.name,
              scale: newObject.scale,
              position: newObject.position,
              rotation: newObject.rotation,
              type: "mesh",
              diffuseTexture: newObject.diffuseTexture,
              normalTexture: newObject.normalTexture,
              tid: newObject.tid,
            },
            meshDetails,
          );
          await tempObject.setup();
        } else {
          let defaultOBJName = "bunny.obj";
          let meshDetails = await Common.parseOBJFileToJSON(
            "/models/" + defaultOBJName,
            {},
          );
          tempObject = new Mesh(
            this.gl,
            {
              name: newObject.name,
              material: newObject.material ? newObject.material : defaultMat,
              modelName: "bunny.obj",
              scale: newObject.scale,
              rotation: newObject.rotation ? newObject.rotation : null,
              position: newObject.position,
              type: "mesh",
              diffuseTexture: newObject.diffuseTexture,
              normalTexture: newObject.normalTexture,
              tid: newObject.tid,
            },
            meshDetails,
          );
          await tempObject.setup();
        }
      } else if (type == "sphere" && newObject.name) {
        tempObject = new Sphere(this.gl, {
          name: newObject.name,
          type: type,
          material: newObject.material,
          scale: newObject.scale,
          position: newObject.position,
          rotation: newObject.rotation,
          diffuseTexture: newObject.diffuseTexture,
          normalTexture: newObject.normalTexture,
          tid: newObject.tid,
          ...additionalArgs,
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      } else {
        //custom objects TODO
        tempObject = new CustomObject(this.gl, {
          name: newObject.name,
          type: newObject.type,
          material: newObject.material,
          scale: newObject.scale,
          position: newObject.position,
          rotation: newObject.rotation,
          diffuseTexture: newObject.diffuseTexture,
          normalTexture: newObject.normalTexture,
          tid: newObject.tid,
          model: {
            vertices: newObject.model.vertices,
            triangles: newObject.model.triangles,
            normals: newObject.model.normals,
            uvs: newObject.model.uvs,
          },
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      }
    } else {
      //brand new object
      if (type === "cube" && newObject.name) {
        tempObject = new Cube(this.gl, {
          name: newObject.name,
          type: "cube",
          material: defaultMat,
          scale: [1.0, 1.0, 1.0],
          position: [0.0, 0.0, 0.0],
        });
        await tempObject.setup();
      } else if (type === "plane" && newObject.name) {
        tempObject = new Plane(this.gl, {
          name: newObject.name,
          type: "plane",
          material: defaultMat,
          scale: [1.0, 1.0, 1.0],
          position: [0.0, 0.0, 0.0],
        });
        await tempObject.setup();
      } else if (type === "mesh" && newObject.name) {
        if (newObject.uploaded) {
          tempObject = new Mesh(
            this.gl,
            {
              name: newObject.name,
              material: defaultMat,
              modelName: newObject.uploaded.name,
              scale: [1.0, 1.0, 1.0],
              position: [0.0, 0.0, 0.0],
              type: "mesh",
            },
            newObject.uploaded.data,
          );
          await tempObject.setup();
        } else {
          let defaultOBJName = "bunny.obj";
          let meshDetails = await Common.parseOBJFileToJSON(
            "/models/" + defaultOBJName,
            {},
          );
          tempObject = new Mesh(
            this.gl,
            {
              name: newObject.name,
              material: defaultMat,
              modelName: "bunny.obj",
              scale: [1.0, 1.0, 1.0],
              position: [0.0, 0.0, 0.0],
              type: "mesh",
            },
            meshDetails,
          );
          await tempObject.setup();
        }
      } else if (type === "sphere" && newObject.name) {
        tempObject = new Sphere(this.gl, {
          name: newObject.name,
          type: type,
          material: defaultMat,
          scale: [1.0, 1.0, 1.0],
          position: [0.0, 0.0, 0.0],
          rotation: newObject.rotation,
          ...additionalArgs,
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      } else {
        //custom objects TODO
        tempObject = new CustomObject(this.gl, {
          name: newObject.name,
          type: newObject.type,
          material: defaultMat,
          scale: [1.0, 1.0, 1.0],
          position: [0.0, 0.0, 0.0],
          rotation: newObject.rotation,
          model: {
            vertices: newObject.model.vertices,
            triangles: newObject.model.triangles,
            normals: newObject.model.normals,
            uvs: newObject.model.uvs,
          },
        });
        tempObject.parent = newObject.parent;
        await tempObject.setup();
      }
    }
    this.addObject(tempObject);
  }

  // we will set any object's parent that had this as its parent to null when we check in a try catch
  removeObject(object) {
    delete this.objects[object.name];
    this.objectsToLoad--;
    this.refresh();
  }

  getPointLights() {
    return this.pointLights;
  }

  getPointLightsNames() {
    return Object.keys(this.pointLights);
  }

  addPointLight(lightObject) {
    let tempLight = new PointLight(this.gl, {
      name: lightObject.name,
      colour: lightObject.colour ? lightObject.colour : [1.0, 1.0, 1.0],
      position: lightObject.position ? lightObject.position : [0.0, 0.0, 0.0],
      strength: lightObject.strength ? lightObject.strength : 1,
      quadratic: lightObject.quadratic ? lightObject.quadratic : 0.035,
      linear: lightObject.linear ? lightObject.linear : 0.09,
      constant: lightObject.constant ? lightObject.constant : 1,
      nearPlane: lightObject.nearPlane ? lightObject.nearPlane : 0.5,
      farPlane: lightObject.farPlane ? lightObject.farPlane : 100,
      shadow: lightObject.shadow ? lightObject.shadow : 0,
    });
    this.pointLights[tempLight.name] = tempLight;
    this.numPointLights++;
    this.refresh();
    this.selectedObject = tempLight;
  }

  removePointLight(light) {
    delete this.pointLights[light.name];
    this.numPointLights--;
    this.refresh();
  }

  // Settings
  getSceneSettings() {
    return this.sceneSettings;
  }

  setSettings(settings) {
    this.sceneSettings = {
      ...settings,
      ...this.settings,
    };
    // this.sceneSettings = settings;
  }

  // Cameras
  getCameras(name) {
    return this.cameras[name];
  }

  addCamera(camera) {
    this.cameras[camera.name] = new Camera(camera);
    if (!this.activeCamera) {
      this.activeCamera = this.cameras[camera.name].name;
    }
  }

  setSelectedObject(object) {
    this.selectedObject = object;
    // TODO look at object when selected (camera pitch/yaw/roll being dumb)
    // let lookAtMatrix = mat4.create();
    // let cam = this.getActiveCamera();

    // mat4.lookAt(
    //     lookAtMatrix,
    //     cam.position,
    //     object.model.position,
    //     cam.up
    // );

    // let resultant = vec4.fromValues(
    //     object.centroid[0],
    //     object.centroid[1],
    //     object.centroid[2],
    //     1.0);

    // vec4.transformMat4(resultant, resultant, object.model.modelMatrix);
    // this.cameras[this.activeCamera].front = vec3.fromValues(resultant[0], resultant[1], resultant[2]);
  }

  getSelectedObject() {
    return this.selectedObject;
  }

  setActiveCamera(name) {
    try {
      this.activeCamera = this.cameras[name].name;
    } catch (err) {
      alert(err);
    }
  }

  getActiveCamera() {
    return this.cameras[this.activeCamera];
  }

  refresh() {
    this.render = true;
  }

  initProjection(details) {
    // set the cameras info from these details
    this.near = details.near;
    this.far = details.far;
    this.fov = details.fov;

    let fovy = (this.fov * Math.PI) / 180.0; // Vertical field of view in radians
    let aspect = this.canvas.clientWidth / this.canvas.clientHeight; // Aspect ratio of the canvas
    mat4.perspective(this.projectionMatrix, fovy, aspect, this.near, this.far);
  }

  initPickerBuffers() {
    this.picker.frameBuffer = this.gl.createFramebuffer();
    this.picker.depthBuffer = this.gl.createRenderbuffer();
    this.picker.targetTexture = this.gl.createTexture();
  }

  drawScene() {
    this.renderer.drawRegular({
      objects: Object.values(this.objects),
      pointLights: Object.values(this.pointLights),
      selectedObject: this.selectedObject,
      dimensions: this.dimensions,
      sceneSettings: this.getSceneSettings(),
      activeCam: this.getActiveCamera(),
      projectionMatrix: this.projectionMatrix,
      numPointLights: this.numPointLights,
      skyBox: this.skyBox,
    });
    this.render = false;
  }

  drawPickerScene(handleSelect) {
    this.renderer.drawPicker({
      picker: this.picker,
      objects: Object.values(this.objects),
      projectionMatrix: this.projectionMatrix,
      activeCam: this.getActiveCamera(),
    });

    if (this.picker.active) {
      this.checkPickerSelection(handleSelect);
    }
  }

  drawStencilScene() {
    this.renderer.drawStencil({
      selectedObject: this.selectedObject,
      projectionMatrix: this.projectionMatrix,
      activeCam: this.getActiveCamera(),
    });
  }

  drawSkyboxScene() {
    this.renderer.drawSkybox({
      skyBox: this.skyBox,
      sceneSettings: this.getSceneSettings(),
      projectionMatrix: this.projectionMatrix,
      activeCam: this.getActiveCamera(),
    });
  }

  drawFullScene(handleSelect) {
    this.drawPickerScene(handleSelect);
    this.drawScene();
    this.drawStencilScene();
    if (this.skyBoxOn) {
      this.drawSkyboxScene();
    }
  }

  checkPickerSelection(handleSelect) {
    let gl = this.gl;
    this.picker.active = false;
    const pixelX =
      (this.picker.mouseX * this.canvas.width) / this.canvas.clientWidth;
    const pixelY =
      this.canvas.height -
      (this.picker.mouseY * this.canvas.height) / this.canvas.clientHeight -
      1;
    const data = new Float32Array(4);
    gl.readPixels(
      pixelX, // x
      pixelY, // y
      1, // width
      1, // height
      gl.RGBA, // format
      gl.FLOAT, // type
      data,
    ); // typed array to hold result
    let roundedData = [
      data[0].toFixed(5),
      data[1].toFixed(5),
      data[2].toFixed(5),
      data[2].toFixed(5),
    ];
    let found = false;
    let objectArray = Object.values(this.objects);

    for (let i = 0; i < objectArray.length; i++) {
      if (
        objectArray[i].id &&
        objectArray[i].id[0] === roundedData[0] &&
        objectArray[i].id[1] === roundedData[1] &&
        objectArray[i].id[2] === roundedData[2]
      ) {
        this.selectedObject = objectArray[i];
        handleSelect(this.selectedObject);
        found = true;
        break;
      }
    }

    if (!found) {
      this.selectedObject = null;
      handleSelect(null);
    }
  }

  async cloneObject(object) {
    let name = object.name;
    // check if the object name with a duplicate already exists
    while (this.objects[name]) {
      name += "-copy";
    }

    let createdObject;
    if (object.type === "pointLight") {
      let lightObject = JSON.parse(JSON.stringify(object));
      this.addPointLight({
        name: name,
        colour: lightObject.colour,
        position: lightObject.position,
        strength: lightObject.strength,
        quadratic: lightObject.quadratic,
        linear: lightObject.linear,
        constant: lightObject.constant,
        nearPlane: lightObject.nearPlane,
        farPlane: lightObject.farPlane,
        shadow: lightObject.shadow,
      });
      return;
    } else if (object.type === "cube") {
      createdObject = new Cube(this.gl, {
        name: name,
        type: object.type,
        material: { ...object.material },
        scale: [...object.model.scale],
        position: [...object.model.position],
        rotation: [...object.model.rotation],
      });
    } else if (object.type === "plane") {
      createdObject = new Plane(this.gl, {
        name: name,
        type: object.type,
        material: { ...object.material },
        scale: [...object.model.scale],
        position: [...object.model.position],
        rotation: [...object.model.rotation],
      });
    } else if (object.type === "mesh") {
      let defaultOBJName = "bunny.obj";
      let meshDetails = await Common.parseOBJFileToJSON(
        "/models/" + defaultOBJName,
        {},
      );
      createdObject = new Mesh(
        this.gl,
        {
          name: name,
          scale: [...object.model.scale],
          position: [...object.model.position],
          rotation: [...object.model.rotation],
          modelName: "bunny.obj",
          material: { ...object.material },
          type: "mesh",
        },
        meshDetails,
      );
    } else if (object.type === "sphere") {
      createdObject = new Sphere(this.gl, {
        name: name,
        type: object.type,
        material: { ...object.material },
        scale: [...object.model.scale],
        position: [...object.model.position],
        rotation: [...object.model.rotation],
        radius: object.radius,
        horizontalSegments: object.horizontalSegments,
        verticalSegments: object.verticalSegments,
        smooth: object.smooth,
      });
    } else {
      //custom object
      createdObject = new CustomObject(this.gl, {
        name: name,
        type: object.type,
        material: { ...object.material },
        scale: [...object.model.scale],
        position: [...object.model.position],
        rotation: [...object.model.rotation],
        model: {
          vertices: [...object.model.vertices],
          triangles: [...object.model.triangles],
          normals: [...object.model.normals],
          uvs: [...object.model.uvs],
        },
      });
    }
    await createdObject.setup();
    this.addObject(createdObject);
    this.refresh();
  }

  reset() {
    this.gameStarted = false;
    this.objects = {};
    this.pointLights = {};
    this.objectsToLoad = 0;
    this.numPointLights = 0;
    this.activeCamera = null;
    this.selectedObject = null;
  }

  async saveNewCustomObject(object) {
    let newVerts = [];

    for (let i = 0; i < object.model.vertices.length; i += 3) {
      let tempVertex = vec4.fromValues(
        object.model.vertices[i],
        object.model.vertices[i + 1],
        object.model.vertices[i + 2],
        1.0,
      );
      vec4.transformMat4(tempVertex, tempVertex, object.model.modelMatrix);
      newVerts.push(tempVertex[0], tempVertex[1], tempVertex[2]);
    }

    let newObject = new CustomObject(this.gl, {
      name: object.name,
      type: object.type.includes("Custom")
        ? object.type
        : object.type + "Custom",
      material: object.material,
      scale: [1.0, 1.0, 1.0],
      rotation: mat4.create(),
      position: object.model.position,
      model: {
        vertices: newVerts.flat(),
        triangles: object.model.triangles,
        normals: object.model.normals,
        uvs: object.model.uvs,
      },
    });
    await newObject.setup();
    this.removeObject(object);
    this.addObject(newObject);
    this.selectedObject = newObject;
  }
}
